/**
 * 该省略组件支持（注：该组件需要元素显示出来才能用，如popver内部未展示则不工作）
 * 1. 无需设置宽度，宽度根据父容器自适应
 * 2. 出现省略号时，显示title
 * 3. 传入行数不超过5
 */
import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { Bubble } from '@tencent/tea-component';
import styles from './tooltips.less?local';

interface EllipsisRrops {
  style?: any;
  className?: string;
  children: any;
  lineNum?: number;
}

const ShowTooltips = (props: EllipsisRrops) => {
  const lineNum = props.lineNum && props.lineNum < 6 ? props.lineNum : 1;
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const domRef = useRef(null);
  const domOneRef = useRef<any>(null);
  const [oneSize, setOneSize] = useState({
    width: 0,
    height: 0,
  });
  const initlineHeightRef = useRef(0);
  const timeClock = useRef<any>(null);
  const [loading, setLoading] = useState(true);

  const init = useCallback(() => {
    if (domOneRef.current) {
      const heightOneLine = parseInt(domOneRef.current.clientHeight);
      if (heightOneLine) {
        initlineHeightRef.current = heightOneLine;
      }
    }
    setLoading(false);
  }, [domOneRef.current]);

  const setSize = useCallback(() => {
    const heightLine = domRef.current ? parseInt(domRef.current.clientHeight) : 0;
    const widthLine = domRef.current ? parseInt(domRef.current.clientWidth) : 0;
    if (heightLine > initlineHeightRef.current) {
      setHasEllipsis(true);
      setOneSize({
        width: widthLine,
        height: initlineHeightRef.current,
      });
    }
  }, [initlineHeightRef.current, domRef.current]);

  useEffect(() => {
    if (loading) {
      setHasEllipsis(false);
      window.setTimeout(() => {
        init();
      }, 10);
    } else {
      setSize();
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
  }, [props.children]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (timeClock.current) {
        window.clearTimeout(timeClock.current);
      }
      timeClock.current = window.setTimeout(() => {
        setLoading(true);
      }, 50);
    });
  }, []);

  const renderNbsp = useMemo(() => {
    const arr = [];
    for (let i = 0;i < lineNum;i++) {
      arr.push('');
    }
    return (<>
      {arr.map(() => (<>&nbsp;<br /></>))}
    </>);
  }, [lineNum]);

  // loading区域用来计算一行文本的高度
  return (
    <>
      {loading ? (
        <div
          ref={domOneRef}
          style={props.style ? props.style : {}}
          className={`${props.className ? props.className : ''}`}
        >
          {renderNbsp}
        </div>
      ) : (<div
        className={`${props.className ? props.className : ''}`}
        style={props.style ? props.style : {}}
      >
        {hasEllipsis ? (
          <Bubble placement="top" dark content={props.children} overlayStyle={{ maxWidth: 400 }}>
            <div
              ref={domRef}
              style={{ height: oneSize.height, width: oneSize.width }}
              className={`${styles['tooltips-ellipsis']} ${styles[`ellipsis-${lineNum}`]}`}
            >
              {props.children}
            </div>
          </Bubble>
        ) : (
          <div ref={domRef} style={{
            height: 'auto',
            maxHeight: initlineHeightRef.current + 5,
            overflow: 'hidden',
          }} className={styles['orgin-div']}>
            {props.children}
          </div>
        )}
      </div>)}
    </>
  );
};

export default ShowTooltips;
