
import ResponseHelper from '@common/responseHelper';
import { message } from '@tencent/adp-combine-ui';
import { updateZhbCommonHeader } from './util';

// function isAbsoluteUrl(url) {
//   return AbsoluteUrlReg.test(url)
// }

const config = {
  CORS: [ZhbHost],

  enableAutoRedirect: true,

  responseHelperMap: {
    [ZhbHost]: ResponseHelper,
  },

  beforeRequest(config) {
    return updateZhbCommonHeader(config);
  },

  beforeResponse(res, config) {
    const { data } = res;
    const responseHelper = new ResponseHelper(data);

    const response = responseHelper.getResponse();

    // 判断是否登录
    if (response.code && `${response.code}` === '401' && config.enableAutoRedirect) {
      let redirectUrl = window.location.pathname;
      if (redirectUrl.indexOf('/login') !== -1) {
        redirectUrl = '/';
      }
      if (!(window.location.pathname === '' || window.location.pathname === '/')) {
        window.location.href = `/login?url=${encodeURIComponent(redirectUrl)}`;
      }
    }

    if (!responseHelper.isSuccess() && res.config.showError !== false) {
      message.error(responseHelper.getMessage());
    }

    return new ResponseHelper(data);
  },
};

export default config;
