const HOST = ZhbHost;
const WECOM_TOOL_API_HOST = WecomToolApiHost;
const CORS = [HOST];
const COOKIE_AUTH_TOKEN = 'token';
const COOKIE_FEATURE_FLAGS = 'featureFlag';

// 存储COOKIE_FEATURE_FLAGS 的index位置, 该序号为自增
enum FeatureTags {
  MUED_IS_FOLD = 0,
  AI_IS_FIRST_VISIT = 1,
  SHOPPING_HELP_IS_FIRST_VISIT = 2,
}

const api = {
  // 获取cos临时凭证
  cosCredential: `${HOST}/basicb/api/v1/baseAdmin/MaterialCos/getTmpCredential`,

  // 获取APPID
  getAppId: `${HOST}/storeb/v1/wechatbind/getAppId`,

  // 私域新增活动
  privateActivityAdd: `${HOST}/api/marketing/activity/save`,
  // 私域查询活动列表
  getPrivateActivityList: `${HOST}/api/marketing/activity/list`,
  // 查询某个私域活动详情
  getActivityDtail: `${HOST}/api/marketing/activity/detail`,
  // 启动活动
  startPrivateActivity: `${HOST}/api/marketing/activity/start`,
  // 停止活动
  stopPrivateActivity: `${HOST}/api/marketing/activity/stop`,
  // 删除活动
  deletePrivateActivity: `${HOST}/api/marketing/activity/delete`,
  // 拉取公众号列表
  getWXAppList: `${HOST}/api/marketing/external/publicAccounts`,
  // 检查欢迎语配置是否已经存在
  checkIsExistConfig: `${HOST}/api/marketing/external/isExsitConfig`,
  // 检查粉丝来源是否重复
  checkIsExistFansConfig: `${HOST}/api/marketing/activity/checkFansSource`,
  // 获取芝麻小客服免登录token
  getZhiMaToken: `${HOST}/api/marketing/external/getToken`,
  // 校验活动名称
  checkActivityName: `${HOST}/api/marketing/activity/checkName`,

  // 判断广告回调是否配置好了
  checkADBackUrl: `${HOST}/api/marketing/ad/checkbind`,
  // 获取商家绑定了小程序列表
  getBindMiniApps: `${HOST}/api/marketing/external/miniProgramAccounts`,

  // 获取微信广告绑定状态
  getAdBindStatus: `${HOST}/api/marketing/external/ad/checkV2`,

  // 获取广告回调url
  getAdBindUrl: `${HOST}/api/marketing/external/ad/url`,
  // 活动列表页是否绑定了公众号和小程序
  getActivityListNoBandList: `${HOST}/api/marketing/activity/configRemind`,

  // 新增枫树小程序
  addMiniApp: `${HOST}/api/marketing/external/miniProgramAccount/save`,

  // 删除枫树小程序
  delMiniApp: `${HOST}/api/marketing/external/miniProgramAccount/delete`,

};

export {
  CORS,
  HOST,
  api,
  COOKIE_AUTH_TOKEN,
  COOKIE_FEATURE_FLAGS,
  FeatureTags,
  WECOM_TOOL_API_HOST,
};
