import React from 'react';
import styles from './toolbar.less?local';

interface ContainerProps {
  children: any;
  style?: any;
}

const ToolbarContainer = (props: ContainerProps) => (
  <div className={styles.content} style={props.style || {}}>
    {props.children}
    <div className={styles.clear}></div>
  </div>
);

ToolbarContainer.left = (props: ContainerProps) => (
  <div className={styles.left}>{props.children}</div>
);

ToolbarContainer.right = (props: ContainerProps) => (
  <div className={styles.right}>{props.children}</div>
);

ToolbarContainer.rightTop = (props: ContainerProps) => (
  <div className={styles['right-top']}>{props.children}</div>
);

export default ToolbarContainer;
