export const validatePasswordFormat = (text: string) => {
  const regex = /^(?!\s)[a-zA-Z0-9]{8,14}(?<!\s)$/;
  const result = regex.test(text);
  return result;
};

export const validatePhoneFormat = (text: string) => {
  const regex = /^1[0-9]{10}$/;
  const result = regex.test(text);
  return result;
};

export function validateNumber(str: string): boolean {
  // 去除前后空格
  const strTrim = str.trim();
  if (strTrim !== str) {
    return false;
  }

  // 检查是否为数字
  if (/^\d+$/.test(strTrim)) {
    return true;
  } else {
    return false;
  }
}
