import React, { useState, useEffect } from 'react';
import { Modal, message } from '@tencent/adp-combine-ui';
// import { Button } from '@tencent/tea-component';
import { Input } from 'maker/components/zhb/adp';
import AuthCodeGetter from 'maker/components/zhb/account/authCodeGetter/index';
import { FormItem } from 'maker/components/zhb/container';
// import { updateAIGoods } from 'maker/services/privateTraffic/wechatAI';
import { changePassword } from 'maker/services/zhb/accountManage';
import { validateNumber, validatePasswordFormat } from '../util';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import CryptoJS from 'crypto-js';
import IconViewPass from 'maker/assets/accountManage/show_pass.png';
import IconHidePass from 'maker/assets/accountManage/hide_pass.svg';
import styles from './modifyPassword.less?local';
interface ModifyPasswordDialogProps {
  title?: string;
  onOk?: () => void;
  dialogRef: any;
}

const ModifyPasswordDialog = (props: ModifyPasswordDialogProps) => {
  const { phone } = useGlobalStore();
  const [show, setShow] = useState<boolean>(false);
  const title = '修改账号密码';
  // const [oldPassword, setOldpassword] = useState<string>('');
  // const [oldPasswordPassMode, setOldpasswordPassMode] = useState(true);
  const [smsCode, setSmsCode] = useState<string>('');

  const [newPassword, setNewpassword] = useState<string>('');
  const [newPasswordPassMode, setNewpasswordPassMode] = useState(true);

  // const [newPasswordAgain, setNewpasswordAgain] = useState<string>('');
  // const [newPasswordPassAgainMode, setNewpasswordPassAgainMode] = useState(true);

  const onOk = async () => {
    if (!validateNumber(smsCode)) {
      message.warning('验证码格式不正确');
      // inputUid.current?.setInputIsErrorStatus(true);
      // inputUid.current?.setInputErrorText('广告账户必填');
      return;
    }

    if (!validatePasswordFormat(newPassword)) {
      message.warning('新密码格式不正确');
      // inputUid.current?.setInputIsErrorStatus(true);
      // inputUid.current?.setInputErrorText('广告账户必填');
      return;
    }

    // if (!validatePasswordFormat(newPasswordAgain)) {
    // message.warning('确认密码的格式不正确');
    // inputUid.current?.setInputIsErrorStatus(true);
    // inputUid.current?.setInputErrorText('广告账户必填');
    // return;
    // }

    // if (newPassword !== newPasswordAgain) {
    // message.warning('新密码和确认密码需要相同');
    // inputUid.current?.setInputIsErrorStatus(true);
    // inputUid.current?.setInputErrorText('广告账户必填');
    // return;
    // }

    const params = {
      // oldPassword,
      verifyCode: smsCode,
      password: CryptoJS.MD5(newPassword).toString(),
    };

    const res = await changePassword(params);
    if (res?.response && res.response.code === 200) {
      message.success('修改成功');
      if (props.onOk) {
        props.onOk();
      }
      setShow(false);
    } else {
      // message.warning('添加失败');
    }
  };

  if (props.dialogRef) {
    props.dialogRef.current = {
      // 添加
      onAddShow: () => {
        // setTitle('新建绑定');
        setShow(true);
        // idRef.current = 0;
      },
    };
  };

  useEffect(() => {
  }, []);

  return (<>
    <Modal
      title={title}
      show={show}
      onOk={onOk}
      onCancel={() => {

      }}
      onVisibleChange={(val: boolean) => (val ? setShow(true) : setShow(false))}
      modalClassName="000-modal-class-name"
      modalContentClassName="000-modal-content-class-name"
      headerClassName="000-header-class-name"
      bodyClassName="000-body-class-name"
      footerClassName="000-footer-class-name"
      width={540}
    >
      <div>
        <FormItem style={{ display: 'flex', alignItems: 'center' }} label="手机号" require>
          <span>{phone}</span>
        </FormItem>
        <FormItem label="验证码" require>
          <Input width={380} value={smsCode} placeholder='请输入验证码'
            // inputRef={inputUid}
            onChange={(v: string) => {
              setSmsCode(v);
            }}
            rightChildren={
              <div className={styles.getAuthCode}>
                <AuthCodeGetter phone={phone} />
              </div>
            }
          />
        </FormItem>
        {/* <FormItem label="原密码" require>
          <Input width={380} value={oldPassword}
            passMode={oldPasswordPassMode} placeholder='8-14字，同时包含数字和字母'
            rightChildren={
              <div className={styles.getAuthCode}>
                <img onClick={() => {
                  setOldpasswordPassMode(!oldPasswordPassMode);
                }}
                src={oldPasswordPassMode ? IconViewPass : IconHidePass} width={16} height={16} />
              </div>
            }
            onChange={(v: string) => {
              setOldpassword(v);
            }} />
        </FormItem> */}
        <FormItem label="新密码" require>
          <Input width={380} value={newPassword} passMode={newPasswordPassMode} placeholder='8-14字，同时包含数字和字母'
            rightChildren={
              <div className={styles.getAuthCode}>
                <img onClick={() => {
                  setNewpasswordPassMode(!newPasswordPassMode);
                }}
                src={!newPasswordPassMode ? IconViewPass : IconHidePass} width={16} height={16} />
              </div>
            }
            onChange={(v: string) => {
              setNewpassword(v);
            }} />
        </FormItem>
        {/* <FormItem label="确认密码" require>
          <Input width={380} value={newPasswordAgain} passMode={newPasswordPassAgainMode} placeholder='8-14字，同时包含数字和字母'
            rightChildren={
              <div className={styles.getAuthCode}>
                <img onClick={() => {
                  setNewpasswordPassAgainMode(!newPasswordPassAgainMode);
                }}
                src={newPasswordPassAgainMode ? IconViewPass : IconHidePass} width={16} height={16} />
              </div>
            }
            onChange={(v: string) => {
              setNewpasswordAgain(v);
            }} />
          </FormItem>*/}
      </div>
    </Modal>
  </>);
};

export default ModifyPasswordDialog;
