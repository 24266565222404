const wordpackageApi = {
  // 查询转化宝所有账号列表
  getSassList: `${ZhbHost}/api/marketing/external/shop/getAll`,

  // 查询转化宝店铺下企微主体列表
  getCorpList: `${ZhbHost}/api/marketing/wordpackage/corp/list`,

  // 查询词包绑定效果
  wordpackageResult: `${ZhbHost}/api/marketing/wordpackage/result/query`,

  // 保存/修改词包标签
  wordpackageTagSave: `${ZhbHost}/api/marketing/wordpackage/tag/save`,

  wordpackageTagSaveV2: `${ZhbHost}/api/marketing/wordpackage/bind/savev2`,

  wordpackageBindDel: `${ZhbHost}/api/marketing/wordpackage/bind/del`,

  wordpackageBindList: `${ZhbHost}/api/marketing/wordpackage/bind/list`,

  wordpackageBindQuery: `${ZhbHost}/api/marketing/wordpackage/bind/query`,

  wordpackageBindSave: `${ZhbHost}/api/marketing/wordpackage/bind/save`,

  wordpackageBindStatus: `${ZhbHost}/api/marketing/wordpackage/bind/status`,

  wordpackageTagDel: `${ZhbHost}/api/marketing/wordpackage/tag/del`,

  wordpackageTagList: `${ZhbHost}/api/marketing/wordpackage/tag/list`,

  wordpackageTagQuery: `${ZhbHost}/api/marketing/wordpackage/tag/query`,

};

export { wordpackageApi };
