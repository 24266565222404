/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */

import React from 'react';
import dynamic from 'store/dynamic';
import {
  IconMarketingSandtable,
  IconRiseEngine,
  // IconMarketingDiscover,
} from 'maker/components/Icon/icon';
import { Chart } from '@tencent/adp-icons-react';

import { MenuIdTree } from './const';
import { MenuItem } from './interface';

export const MarketingData: MenuItem = {
  id: MenuIdTree.MARKETING_MENU_ID,
  name: '增长引擎',
  icon: IconRiseEngine,
  children: [
    {
      id: MenuIdTree.MARKETING_MENU_ID__SAND_TABLE,
      name: '经营沙盘',
      url: '/marketing/sandtable',
      icon: IconMarketingSandtable,
      component: dynamic({
        component: () => import('maker/routes/Marketing/sandtable'),
      }),
    },
    {
      id: MenuIdTree.MARKETING_MENU_ID__REPORT,
      name: '经营报表',
      icon: () => <Chart size={16} />,
      // * 以下要根据配置动态替换掉
      children: [],
    },
    // {
    //   id: MenuIdTree.MARKETING_MENU_ID__DISCOVER,
    //   name: '商机发现',
    //   url: '/marketing/discovery',
    //   icon: IconMarketingDiscover,
    //   component: dynamic({
    //     component: () => import('maker/routes/Marketing/discovery'),
    //   }),
    // },
  ],
};
