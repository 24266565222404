const HOST = ZhbHost;

export const aiLabApi = {
  // 历史记录
  getHistoryList: `${HOST}/api/aigc/conversation/list`,
  // 详情
  getConversationDetail: `${HOST}/api/aigc/conversation/detail`,
  // 赞踩反馈
  sendFeedback: `${HOST}/api/aigc/conversation/feedback`,
  // 获取商品类目
  getCategoryList: `${HOST}/api/aigc/conversation/category/list`,
  // 内容/文案生成
  contentGenerate: `${HOST}/api/aigc/conversation/content/generate`,
};
