import React, { useState, useEffect, useCallback } from 'react';
import styles from './multiSelect.less?local';
import { Popover, Checkbox } from '@tencent/adp-combine-ui';
import { ArrowDownOne, ArrowUpOne, CloseOne } from '@tencent/adp-icons-react';

interface SelectPrpos {
  options: any;
  value?: any[];
  onChange?: any;
  renderFooter?: any;
  placeholder?: string;
  searchPlaceholder?: string;
  showNoLimit?: boolean;
  inputWidth?: number;
  disabled?: boolean;
  isSingle?: boolean;
  loading?: boolean;
}

const SelectCompotent = (props: SelectPrpos) => {
  const [isShowPover, setShowPover] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [selectKey, setSelectKey] = useState<any[]>(props.value || []);

  // 点击事件处理
  const onValueChange = (v: string | number) => {
    const index = selectKey.indexOf(v);
    const newSelectKey = [...selectKey];
    if (index === -1) {
      newSelectKey.push(v);
    } else {
      newSelectKey.splice(index, 1);
    }
    if (!props.value) { // 非受控模式
      setSelectKey(newSelectKey);
    }
    if (props.onChange) {
      props.onChange(newSelectKey);
    }
  };

  // 单选
  const onSingleValueChange = (v: string | number) => {
    if (!props.value) { // 非受控模式
      setSelectKey([v]);
    }
    if (props.onChange) {
      props.onChange([v]);
    } 
  };

  // 清除所有值
  const clearAll = () => {
    if (!props.value) { // 非受控模式
      setSelectKey([]);
    }
    if (props.onChange) {
      props.onChange([]);
    }
  };

  // 受控模式，由外层传入value值的变更
  useEffect(()=>{
    if (props.value) {
      setSelectKey(props.value);
    }
  }, [props.value]);

  // 处理选中数据
  const formatOptionData = useCallback(() => {
    const newData: any[] = [];
    const selectLables: string[] = [];
    props.options.forEach((item: any) => {
      item.label = `${item.label}`;
      if (item.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
        const checked = selectKey.includes(item.value);
        newData.push({
          ...item,
          checked,
        });
        if (checked) {
          selectLables.push(item.label);
        }
      }
    });

    return {
      optionData: newData,
      selectText: props.showNoLimit && (selectLables.length === props.options.length || selectLables.length === 0)
        ? '不限' : selectLables.join('，'),
    };
  }, [props.options, selectKey, keyword]);

  const { optionData, selectText } = formatOptionData();

  return (
    <div className={styles['select-wrapper']}>
      {props.disabled         
        ? <div className={styles['selected-item']} title={selectText.length>20 ? selectText: ''}>
          <input 
            type="text" 
            value={selectText}
            readOnly
            placeholder={props.placeholder ? props.placeholder: '请选择'}
            style={{ width: props.inputWidth ? props.inputWidth : '100%' }}
            className={styles['selected-item-input']}
            disabled={true}
          />
          <span className={styles['selected-item-icon']}>
            <ArrowDownOne fill="#4D5869" size={12} />
          </span>          
        </div>
        : <Popover
          trigger="click"
          show={isShowPover}
          placement="bottom-start"
          popoverStyle={{ padding:0, marginTop:-10 }}
          getPopper={()=><div className={styles['popver-wrapper']}>
            {props.loading ? <div className={styles['loading-div']}>loading...</div> : <>
              {props.options.length === 0 ? <div className={styles['empty-div']}>无数据</div> : (
                <>
                  <div className={styles['search-input-wrapper']}>
                    <input 
                      type="text" 
                      value={keyword} 
                      onChange={(e)=>{
                        setKeyword(e.target.value);
                      }}
                      maxLength={10} 
                      placeholder={props.searchPlaceholder || '搜索关键词'}
                      className={styles['search-input']}
                    />
                  </div>
                  <div className={styles['popver-wrapper-body']}>
                    {optionData.map((item: any, index: number)=> <div 
                      className={`${styles.item} ${item.checked ? styles.selected: ''}`}
                      key={`diy-sel-${index}`}
                      onClick={()=>{
                        if (props.isSingle) {
                          onSingleValueChange(item.value);
                          setShowPover(false);
                        } else {
                          onValueChange(item.value);
                        // setShowPover(false);
                        }
                      }}
                    >
                      {props.isSingle ? <div className={styles.option}>
                        {item.label}
                      </div> : <div>
                        <Checkbox checked={item.checked}><div className={styles.option}>{item.label}</div></Checkbox>
                      </div>}
                      {item.label.length > 15 && (<div className={styles['title-hover']}>
                        {item.label}
                      </div>)}
                    </div>)}
                  </div>
                  {props.renderFooter && (<div className={styles['option-more']}>
                    {props.renderFooter}
                  </div>)}
                </>
              )}
            </>}

          </div>}
          onVisibleChange={(v: boolean)=>{
            setShowPover(v);
            if (!v) {
              setKeyword('');
            }
          }}
        >
          <div className={styles['selected-item']} title={selectText.length>20 ? selectText: ''}>
            <input 
              type="text" 
              value={selectText}
              readOnly
              placeholder={
                !isShowPover && props.loading 
                  ? 'loading...' 
                  : (
                    props.placeholder 
                      ? props.placeholder
                      : '请选择')
              }
              style={{ width: props.inputWidth ? props.inputWidth : '100%' }}
              className={styles['selected-item-input']}
            />
            <span className={styles['selected-item-icon']}>
              {isShowPover 
                ? <ArrowUpOne fill="#4D5869" size={12} /> 
                : <ArrowDownOne fill="#4D5869" size={12} />}
            </span>
            {selectKey.length > 0 && (<span className={styles['selected-item-delete-icon']} onClick={(e: any)=>{
              clearAll();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}>
              <CloseOne fill="#868E9F" size={12} />
            </span>)}
          
          </div>
        </Popover>
      }
    </div>
  );
};

export default SelectCompotent;
