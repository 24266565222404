import React, { useState } from 'react';
import { Input, TextArea } from '@tencent/adp-combine-ui';
import styles from './input.less?local';

interface InputProps {
  value: string;
  width?: string | number;
  onChange?: any;
  maxLength?: number;
  maxLengthClassName?: string;
  placeholder?: string;
  inputRef?: any;
  textArea?: boolean;
  textAreaRows?: number;
  disabled?: boolean;
  rightChildren?: React.ReactElement;
  passMode?: boolean;
  append?: string;
  className?: string;
}

const InputCompotent = (props: InputProps) => {
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [errorText, setErrorText] = useState('');
  // const [inputValue, setInputValue] = useState(props.value);

  if (props.inputRef) {
    props.inputRef.current = {
      setInputIsErrorStatus: setIsErrorStatus,
      setInputErrorText: setErrorText,
    };
  }

  // useEffect(() => {
  //   setInputValue(props.value);
  // }, [props.value]);

  return <div className={`${styles['input-wrapper']} ${props.className ? props.className : ''} `} >
    <div className={isErrorStatus ? `${styles.contentDiv} ${styles.errInput}`
      : `${styles.contentDiv}`}>
      {!props.textArea && <div
        className={styles.inputContainer}>
        <Input
        autoComplete='new-password'
        type={!!props.passMode ? 'password' : 'text'}
        disabled={!!props.disabled}
        value={props.value}
        onValueChange={(v) => {
          if (props.onChange) {
            props.onChange(v);
          };
          if (isErrorStatus) {
            setIsErrorStatus(false);
            setErrorText('');
          }
        }}
        prepend=""
        placeholder={props.placeholder ? props.placeholder : ''}
        maxLength={props.maxLength ? props.maxLength : 3000}
        style={{ width: props.width || 200 }}
        append={props.append}
        />
      </div>}
      {props.textArea && <TextArea
        disabled={!!props.disabled}
        rows={props.textAreaRows}
        value={props.value}
        onValueChange={(v) => {
          // setInputValue(v);
          if (props.onChange) {
            props.onChange(v);
          };
          if (isErrorStatus) {
            setIsErrorStatus(false);
            setErrorText('');
          }
        }}
        placeholder={props.placeholder ? props.placeholder : ''}
        maxLength={props.maxLength ? props.maxLength : 3000}
        style={{ width: props.width || 200 }}
      />}
      <span className={styles.contentSpan}>{errorText}</span>
    </div>
    {props.maxLength && <div className={`${styles['number-count']} ${props.maxLengthClassName ? props.maxLengthClassName : ''}`}>
      {props.value?.length}/{props.maxLength}</div>}
    {props.rightChildren ? props.rightChildren : <></>}
  </div>;
};

export default InputCompotent;
