/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */
import dynamic from 'store/dynamic';
import {
  IconGrant,
  IconAccountManage,
  IconOpsTool,
} from 'maker/components/Icon/icon';

import { SETTING_MENU_ID } from './const';

export const SettingsData = {
  id: SETTING_MENU_ID,
  name: '系统设置',
  url: '',
  icon: IconOpsTool,
  children: [
    {
      id: 405,
      name: '授权管理',
      icon: IconGrant,
      children: [{
        id: 31,
        name: '广告应用授权',
        url: '/wecomAuth',
        component: dynamic({
          component: () => import('maker/routes/AdPrivateTraffic/wecomSidebar/wecomAuth'),
        }),
      },
      // {
      //   id: 311,
      //   name: '企微营销授权',
      //   url: '/scrm/setting/auth/wxwork',
      //   component: dynamic({
      //     component: () => import('maker/routes/SubApp/wujie'),
      //   }),
      // }
      ],
    },
    {
      id: 500,
      name: '员工账号管理',
      icon: IconAccountManage,
      children: [{
        id: 38,
        name: '角色管理',
        url: '/commonSetting',
        component: dynamic({
          component: () => import('maker/routes/AccountManage/RoleManage/index'),
        }),
      }, {
        id: 39,
        name: '广告账户绑定授权',
        url: '/commonSetting/adUidBind',
        component: dynamic({
          component: () => import('maker/routes/AccountManage/AdUidBind/index'),
        }),
      }, {
        id: 310,
        name: '子账号管理',
        url: '/commonSetting/subAccountManange',
        component: dynamic({
          component: () => import('maker/routes/AccountManage/SubAccountManange/index'),
        }),
      },
      ],
    },
  ],
};
