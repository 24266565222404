import { Request } from '@common/requestHelper';
import { aiApi } from 'maker/config';
import moize from 'moize';

const unifyRequest = async (api: string, params: any) => {
  const attachParams = { showError: false };
  const res = await Request.post(api, params, attachParams);
  if (!res.response || (res.response?.code === 500 && res.response.msg === '登录态失效，请重新登录')) {
    // window.location.href = '/login';
  }
  return res;
};

// 获取商品列表
export const getAIGoodsList = async (params: any) => unifyRequest(aiApi.aiGoodsList, params);

// 更新商品
export const updateAIGoods = async (params: any) => unifyRequest(aiApi.aiGoodsUpdate, params);

// 查询导购列表
export const getAIProfileList = async (params: any) => unifyRequest(aiApi.aiProfileList, params);

// 更新导购人设
export const updateAIProfile = async (params: any) => unifyRequest(aiApi.aiProfileUpdate, params);

// 查询收藏列表
export const getAIFavoriteList = async (params: any) => unifyRequest(aiApi.aiFavoriteList, params);

// 更新收藏
export const updateAIFavorite = async (params: any) => unifyRequest(aiApi.aiFavoriteUpdate, params);

// 获取答案
export const aiGetAnswer = async (params: any) => unifyRequest(aiApi.aiGetAnswer, params);

// 获取初始化数据
export const aiGetInitData = async (params: any) => unifyRequest(aiApi.aiGetInitData, params);

// 反馈
export const aiFeedback = async (params: any) => unifyRequest(aiApi.aiFeedback, params);

// 获取白名单
// 这里加了个moize缓存。因为白名单管控封装了hook，避免用的地方多了重复请求。造成浪费
export const getWhiteList = moize({
  maxAge: 5 * 60 * 1000, // 5min缓存
  isDeepEqual: true,
  isPromise: true,
})(async (params: any) => {
  const responseHeader = await unifyRequest(aiApi.getWhiteList, params);
  return responseHeader.response;
});
