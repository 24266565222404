/**
 * @file index.ts
 * @author markJia(markjia@tencent.com)
 */

import { ResAnalysisTlpListData, RespApiGetThirdUserId, RespSimilarTplGroupList, RespTplLabelList } from './analysis/api';

export enum ZhbApiType {
  API_GET_ANALYSIS_LABEL_LIST = 'API_GET_ANALYSIS_LABEL_LIST',
  API_GET_ANALYSIS_TLP_LIST= 'API_GET_ANALYSIS_TLP_LIST',
  API_GET_ANALYSIS_TPL_GROUP_LIST = 'API_GET_ANALYSIS_TPL_GROUP_LIST',
  API_GET_THIRD_USER_ID = 'API_GET_THIRD_USER_ID',
  API_GET_SHARE_BEACON_URL = 'API_GET_SHARE_BEACON_URL',
}

export const ZHB_API_URLS: Record<ZhbApiType, string> = {
  // 获取话术模板查询条件
  [ZhbApiType.API_GET_ANALYSIS_LABEL_LIST]: '/api/marketing/data/analysis/tools/template/getConditionList',

  // 获取相似模板id
  [ZhbApiType.API_GET_ANALYSIS_TPL_GROUP_LIST]: '/api/marketing/data/analysis/sandtable/template/similarGroupId/list',

  // 获取模板详情
  [ZhbApiType.API_GET_ANALYSIS_TLP_LIST]: '/api/marketing/data/analysis/sandtable/template/list',

  [ZhbApiType.API_GET_THIRD_USER_ID]: '/api/marketing/data/analysis/sandtable/user/getThirdUserId',

  [ZhbApiType.API_GET_SHARE_BEACON_URL]: '/api/marketing/wecom/lighthouse/datatalk/page/info',
};

export interface ZhbApiResp {
  [ZhbApiType.API_GET_ANALYSIS_LABEL_LIST]: RespTplLabelList;
  [ZhbApiType.API_GET_ANALYSIS_TPL_GROUP_LIST]: RespSimilarTplGroupList;
  [ZhbApiType.API_GET_ANALYSIS_TLP_LIST]: ResAnalysisTlpListData;
  [ZhbApiType.API_GET_THIRD_USER_ID]: RespApiGetThirdUserId;
  [ZhbApiType.API_GET_SHARE_BEACON_URL]: string; // ResCreateBeaconShareUrl;
};


