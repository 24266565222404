import React, { useLayoutEffect, useState } from 'react';
import { getWhiteList } from '../services/privateTraffic/wechatAI';
import { WHITE_LIST } from '@/maker/common/systemConst';

export type TypeWhitelistProps = {
  whitelist: {
    fetched: boolean;
    auth: boolean;
    powers: Array<string>;
  };
};

type TypeWhitelist = TypeWhitelistProps['whitelist'];

export default function withWhitelist<P>(
  WrappedComponent: React.ComponentType<P>,
  preferWhitelistKey: typeof WHITE_LIST[keyof typeof WHITE_LIST] = '',
  Component401: () => JSX.Element | null = () => (<div>无权限访问</div>),
): React.FC<Omit<P, keyof TypeWhitelistProps>> {
  // 返回新组件
  // 强制把这里提供的prop塞进去
  return (props: P & TypeWhitelistProps) => {
    const [whitelist, setWhitelist] = useState<TypeWhitelist>({
      fetched: false,
      auth: false,
      powers: [],
    });

    useLayoutEffect(() => {
      getWhiteList({})
        .then((res) => {
          const { data: { powers } } = res;
          setWhitelist({
            fetched: true,
            // 不需要白名单（无key），或者key在白名单列表里，则表示有权限
            auth: !preferWhitelistKey || powers?.includes(preferWhitelistKey),
            powers,
          });
        });
    }, [preferWhitelistKey]);

    // 名单未返回前不渲染
    if (!whitelist.fetched) {
      return null;
    }

    // 不在名单内，拦截渲染
    if (!whitelist.auth) {
      return (
        <Component401 />
      );
    }

    // 正常渲染组件
    return (
      <WrappedComponent
        {...props}
        whitelist={whitelist}
      />
    );
  };
}

