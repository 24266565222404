/**
 * @file config.ts
 * @author markJia(markjia@tencent.com)
 */

import { MenuIdTree } from '../../config/menu/const';

export interface BeaconConfigListItem {
  // 该id的字元素会动态添加menu
  injectMenuId: number;
  // 未找拉取到配置时，删除injectMenuId对应的菜单
  noBeaconRemoveMenu: boolean;
  modId: string | null;
  urlPrefix: string;
  subWrapperCls?: string;
}

export const BEACON_CONFIG_LIST: BeaconConfigListItem[] = [
  {
    // 经营报表
    injectMenuId: MenuIdTree.MARKETING_MENU_ID__REPORT,
    noBeaconRemoveMenu: true,
    modId: null,
    urlPrefix: '/marketing/datatalk',
  },
  {
    // 导购辅助报表
    injectMenuId: MenuIdTree.SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT,
    noBeaconRemoveMenu: true,
    modId: 'guideAssistant',
    urlPrefix: '/shopppingHelp/assistant/report',
    subWrapperCls: 'sub-wrapper-style-reset-with-tabs',
  },
];
