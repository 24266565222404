import React, { useRef } from 'react';
import { Pagination, Table } from '@tencent/adp-combine-ui';
import styles from './table.less?local';

interface TableProps {
  loading: any;
  columns: any[];
  dataItems: any[];
  onChange: any;
  total: number;
  pageSize?: number;
  current?: number;
  rowSelection?: any;
  className?: string;
  rowKey?: string;
}
const showTotal = (total: number) => <div className={styles['page-div']}>共 {total} 项数据</div>;
const TableCompotent = (props: TableProps) => {
  const currRef = useRef<number>(1);
  const sorterRef = useRef<any>(null);
  const pageSizeRef = useRef<number>(props.pageSize ? props.pageSize : 10);

  const onChange = (currPage: number, pageSize: number, sorter: any) => {
    currRef.current = currPage;
    sorterRef.current = sorter;
    pageSizeRef.current = pageSize;
    if (!sorter) {
      props.onChange(currPage, pageSize);
    } else {
      props.onChange(currPage, pageSize, sorter);
    }
  };

  const isFixed = props.columns.filter(item => item.fixed !== undefined).length > 0 && props.dataItems.length > 0;

  return (<>
    <div className={`${styles['table-wrapper']} ${props.className ? props.className : ''} ${props.columns.length > 10 ? styles['too-many-columns'] : ''}`}>
      <Table
        rowSelection={props.rowSelection || null}
        loading={props.loading}
        columns={props.columns}
        dataSource={props.dataItems}
        pagination={false}
        rowKey={props.rowKey}
        scroll={{ x: isFixed ? 'max-content' : undefined }}
        onChange={(pagination, filters, sorter) => {
          onChange(1, pageSizeRef.current, sorter);
        }}
      />
    </div>
    {(props.pageSize !== undefined && props.pageSize > 0) && <div className={styles['page-wrapper']}>
      <Pagination
        defaultCurrent={1}
        total={props.total}
        current={(props.current && props.current > 0)
          ? props.current : currRef.current}
        defaultPageSize={pageSizeRef.current}
        showTotal={showTotal}
        onChange={(currPage: number, pageSize: number) => {
          onChange(currPage, pageSize, sorterRef.current);
        }}
        showSizeChanger={true}
        onShowSizeChange={(currPage: number, pageSize: number) => {
          onChange(currPage, pageSize, sorterRef.current);
        }}
      />
    </div>}
  </>);
};

export default TableCompotent;
