import React, { memo, useEffect, useMemo } from 'react';
import WujieReact from 'wujie-react';
import { getSubAccountList } from 'maker/services/zhb/accountManage';
import { SubAccountListResponse } from 'maker/types/models/zhb/getSubAccountList';
import { ScrmData } from 'maker/config/menu/scrm';
import { SettingsData } from 'maker/config/menu/settings';
import { useGlobalStore } from 'maker/hooks/useGlobalStore';
import WithCheckCorp from 'maker/use/withCheckCorp';
import { queryWecomAuthoritylist } from 'maker/services/privateTraffic/wecomSidebar';
import {
  AuthoritylistResponse,
  // DataList,
} from 'maker/types/models/privateTraffic/wecomAuthorityList';

const { bus } = WujieReact;

interface WujieMemolizeProps {
  isSub: boolean;
  subUrl: string;
}

const WujieMemolize = memo((props: WujieMemolizeProps): JSX.Element => {
  const { isSub, subUrl } = props;

  let url = `${ClimbHost}${subUrl}`;
  const searchParams = new URLSearchParams(window.location.search);
  if (!searchParams.has('ts')) {
    searchParams.append('ts', Date.now().toString());
  }
  const paramsStr = searchParams.toString();
  const split = url.includes('?') ? '&' : '?';
  url += `${split}${paramsStr}`;

  const position = useMemo(() => (isSub ? {
    left: 228,
    top: 162,
  } : {
    left: 204,
    top: 88,
  }), [isSub]);

  return (
    <div style={{
      borderRadius: 4,
      height: '100%',
      paddingTop: 1,
      backgroundColor: '#fff',
    }}>
      <div style={{ marginLeft: - position.left, marginTop: - position.top, minHeight: '100%' }}>
        <WujieReact
          key={subUrl}
          alive={true}
          name={`climb-web-${props.isSub ? 'sub' : 'main'}`}
          sync={false}
          plugins={
            [
              { cssBeforeLoaders: [
                { content: `
          html{padding-top: ${position.top}px !important; padding-left: ${position.left}px !important;}
          body, .tyx-root-container{background-color: #fff !important;}
          .tyx-root-container.full{padding:0 !important;}
          .tyx-root-container .tea-layout__header-title {
            line-height: 22px;padding-bottom: 0 !important;padding-top:16px !important; padding-left:24px !important; }
          .tyx-root-container .tea-layout__header-title .tea-h2,
          .tyx-root-container .tea-layout__header-title .tea-breadcrumb__item-title.is-current {
            font-size:16px !important; font-weight: 500 !important; color: #030303 !important;}
          .tyx-root-container .tea-layout__header-title .tea-breadcrumb__item-title {
            font-size:16px !important;
          }
          .sr-ui-filter-form {
            padding: 0 0 16px 0px !important;
            margin-bottom: 16px;
            border-bottom: #E6E8ED solid 1px;
          }
          .tea-table {border: 1px solid #E6E8ED;}
          .tea-table__box thead > tr > th {
            position: relative;
            font-weight: 500;
            text-align: left;
            background: #F6F9FB;
            transition: background 0.3s ease;
          }
          .tea-table__box th > div {
            color: #868E9F !important;
          }
          .tea-dropdown-filter .tea-dropdown__value {
            font-weight: 500 !important;
          }
          .tea-table__box thead > tr > th:not(:last-child)::before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.06);
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: '';
          }
          .tea-table__box tbody tr.is-selected {
            background-color:#EAF0FD !important;
          }
          ` },
              ],
              },
            ]
          }
          url={url}
        ></WujieReact>
      </div></div>
  );
});

const urlIsInMainRouter = (url: string) => {
  for (const item of ScrmData.children) {
    for (const child of item.children) {
      if (url.indexOf(child.url) !== -1) {
        return true;
      }
    }
  }
  for (const item of SettingsData.children) {
    for (const child of item.children) {
      if (url.indexOf(child.url) !== -1) {
        return true;
      }
    }
  }
  return false;
};

const SubAppModule = (props: any) => {
  const { corpInfo } = useGlobalStore();
  const { history: { location: { pathname } } } = props;
  const subUrl = props.subUrl || pathname.replace(/^\/scrm/, '');

  // 四级菜单的定位值与三级菜单有差别
  const listenUserList = () => {
    // 主应用监听事件
    const getUserList = async (pageNum: 1, pageSize: 100, wd: '') => {
      const params: any = {
        pageSize,
        offset: pageNum,
        status: 0,
        accountName: wd,
      };
      // 获取账号列表
      const res: { response: SubAccountListResponse } = await getSubAccountList(params);
      bus.$emit('sendStaffList', res.response.data || { list: [], total: 0 });
    };

    // 监听获取员工列表事件
    bus.$on('getStaffList', (pageNum: 1, pageSize: 100, wd: '') => {
      getUserList(pageNum, pageSize, wd);
    });
  };

  const listenRouter = () => {
    // 保活模式下，通知子应用切换路由
    bus.$emit('changeRouter', subUrl);

    // 监听子应用路由切换事件
    if (!props.subUrl) {
      bus.$on('subAppRouterChange', (url: string, newWin?: boolean) => {
        if (url.indexOf(subUrl) === -1) {
          // 监听切换的路由应在主应用的配置之中
          if (urlIsInMainRouter(url)) {
            if (newWin) {
              window.open(`https://${window.location.host}${url}`);
            } else {
              props.history.push(url);
            }
          }
        }
      });
    }
  };

  const listenCorpList = () => {
    const getCorpList = async () => {
      const res = await queryWecomAuthoritylist({ pageNum: 1, pageSize: 1000 });
      if (res.isSuccess()) {
        const data: AuthoritylistResponse['data'] = res.getData();
        const { list } = data;

        const newList: string[] = [];
        list.filter((corp: any) => corp.valid).forEach((item: any) => {
          newList.push(item.corpId);
        });
        bus.$emit('sendCorpList', newList);
        // dev test
        // bus.$emit('sendCorpList', ['wpalWwCwAARGskXEsjXjwr1gJqrkH-ig']);
      }
    };

    // 监听
    bus.$on('getCorpList', getCorpList);
  };

  useEffect(() => {
    listenUserList();
    listenRouter();
    listenCorpList();

    return () => {
      // 清除监听, 否则切换菜单会造成重复监听
      bus.$clear();
    };
  }, []);

  // 切换时通知刷新列表
  useEffect(() => {
    bus.$emit('sendRefreshCorpList');
  }, [corpInfo]);

  return <WujieMemolize subUrl={subUrl} isSub={props.isSub} />;
};

export default WithCheckCorp(SubAppModule);
