/**
  特殊菜单常量
 */

export const SETTING_MENU_ID = 14; // 设置
export const TOOL_MENU_ID = 12; // 工具箱ID
export const WECHAT_MUNU_ID = 22;  // AI文案助手
export const SCRM_MUNU_ID = 15;  // SCRM
// 广告服务
export const DATA_MUNU_ID = 13;  // 数据服务
// 增长引擎
export const MARKETING_MUNU_ID = 16;
export const ANALYSIS_ID = 17; // 全链路分析
export const SHOPPING_MENU_ID = 18;  // 导购辅助

export enum MenuIdTree {
  // 增长引擎
  MARKETING_MENU_ID = 16,
  /* */ MARKETING_MENU_ID__SAND_TABLE = 210, // 经营沙盘
  /* */ MARKETING_MENU_ID__REPORT = 1600, // 经营报表
  /*      */ MARKETING_MENU_ID__REPORT____01 = 1600101, // 报表1
  /*      */ MARKETING_MENU_ID__REPORT____02 = 1600102, // 报表2
  /*      */ MARKETING_MENU_ID__REPORT____03 = 1600103, // 报表3
  /*      */ MARKETING_MENU_ID__REPORT____04 = 1600104, // 报表4
  /*      */ MARKETING_MENU_ID__REPORT____05 = 1600105, // 报表5
  /* */ MARKETING_MENU_ID__DISCOVER = 213, // 商机发现

  /** 导购辅助 */
  SHOPPING_MENU_ID = 18,
  /** */ SHOPPING_MENU__SHOP_ASSISTANT = 1800, // 导购辅助--消息推送
  /**      */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_TYPE = 180001, // 服务标准
  /**      */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_CONFIG = 180002, // 服务设置
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_CONFIG___LIST = 18000201, // 服务设置
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_CONFIG___CREATE = 18000202, // 服务设置
  /**      */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT = 180003, // 数据分析
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT__01 = 18000301, // 报表1
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT__02 = 18000302, // 报表2
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT__03 = 18000303, // 报表3
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT__04 = 18000304, // 报表4
  /**          */ SHOPPING_MENU__SHOP_ASSISTANT___SERVICE_REPORT__05 = 18000305, // 报表5
  /**      */ SHOPPING_MENU__SHOP_ASSISTANT__AUTH_CONFIG = 180004, // 授权安装
  /** */ SHOPPING_MENU__AI_SHOPPING = 211,
  /** */ SHOPPING_MENU__SOP = 212,
}
