
const tagApi = {
  // 查询标签组
  tagGroupList: `${ZhbHost}/api/marketing/cdp/taggroup/list`,

  // 查询标签
  tagList: `${ZhbHost}/api/marketing/cdp/tag/list`,

  // 标签组列表
  tagGroupListV2: `${ZhbHost}/corpwx/api/v1/tag/group/list`,

  //  标签列表
  tagListV2: `${ZhbHost}/corpwx/api/v1/tag/list`,

};

export { tagApi };
