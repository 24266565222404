const HOST = ZhbHost;

const scrmApi = {
  // 获取企微主体列表
  getWecomList: `${HOST}/corpwx/api/v1/wecom/list`,

  // 切换默认企微信主体
  setDefaultCorp: `${HOST}/corpwx/api/v1/wecom/setDefaultCorpInfo`,
};

export { scrmApi };
