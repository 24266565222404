/**
 * @file BeaconChart.tsx
 * @author markJia(markjia@tencent.com)
 */

import React from 'react';
import { useMount, useSetState } from 'ahooks';
import { getShareBeaconUrl } from './getBeaconUrl';
import { Spin, message } from '@tencent/adp-combine-ui';

interface BeaconChartProps {
  pageId: number;
  corpId: string;
}

export const BeaconChart = (props: BeaconChartProps): JSX.Element => {
  const { pageId, corpId } = props;

  const [state, setState] = useSetState<{ loading: boolean; url: string }>({ loading: false, url: '' });

  useMount(() => {
    setState({ loading: true });
    getShareBeaconUrl({
      pageId,
      params: JSON.stringify({ corpId }),
      expireTime: 3600 * 24 * 3,
      iframeWebsite: '',
    }).then((res) => {
      const { code, data, message: errMsg } = res;
      if (code !== 0) {
        message.error(errMsg || '服务异常，请稍后重试');
        return;
      }

      const { url } = data;
      setState({ url });
    })
      .finally(() => {
        setState({ loading: false });
      });
  });

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Spin size="sm" spinning={state.loading} wrapperClassName="adp-ui-loading-block-fill">
        {state.url && <iframe src={state.url} style={{ height: 'calc(100% + 115px)', width: '100%' }} />}
      </Spin>
    </div>
  );
};
