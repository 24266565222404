import React, { useState, useEffect } from 'react';
import { Button } from '@tencent/tea-component';
import { getPhoneCode } from 'maker/services/zhb/login';
import './index.less';

interface AuthCodeGetterProps {
  phone: string;
}

const AuthCodeGetter = (props: AuthCodeGetterProps) => {
  const [countdown, setCountdown] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    sendPhoneCode();
    setCountdown(59);
    setDisabled(true);
  };

  /**
   * 获取短信验证码
   */
  const sendPhoneCode = async () => {
    try {
      const response = await getPhoneCode({
        phone: props.phone,
        timestamp: Date.now(),
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setDisabled(false);
      setCountdown(0);
    }
  }, [countdown]);

  return (
    <div className='divAuthCodeGetter'>
      <Button onClick={handleClick} type='link' disabled={disabled}>
        {disabled ? `${countdown}秒后重新获取` : '获取验证码'}
      </Button>
    </div>
  );
};

export default AuthCodeGetter;
