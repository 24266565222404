import React from 'react';
import ReactDOM from 'react-dom';

// import store from 'store/store'
import Request from '@common/requestHelper';
import config from '@common/requestHelper/config';
import App from './App';
import './common/aegis';

Request.setting(config);

// 拦截未处理的rejection
window.onunhandledrejection = function (data) {
  console.warn('未处理的错误', data.reason);
};

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render();
