
import { clearSessions } from 'maker/utils/session';
import { message } from '@tencent/adp-combine-ui';
import React, {
  PropsWithoutRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LinkProps, Link } from 'react-router-dom';
export const HOME_PAGE = '/redict';
const LOGIN_PAGE = '/login';
export function goHomepage(state: any) {
  const { nickName, history, MENU } = state;
  // 首页
  let indexUrl = '';
  if (nickName) {
    const menu = MENU.getFirstMenu();
    if (menu) {
      indexUrl = menu.url;
    }
    if (indexUrl) {
      history.push(indexUrl);
    } else {
      message.error('您没有访问该系统的权限');
    }
  }
}

export function goLoginPage(history: any, redirect = '') {
  clearSessions();
  let loginUrl = LOGIN_PAGE;

  const { pathname } = window.location;
  if (pathname.indexOf(LOGIN_PAGE) >= 0) {
    return;
  }

  if (redirect) {
    loginUrl = `${LOGIN_PAGE}?redirect=${encodeURIComponent(redirect)}`;
  }
  history.replace(loginUrl);
}

export function reload(pathname = '/', redirect = '') {
  const targetPath = /^\//.test(pathname) ? pathname : `/${pathname}`;
  window.location.href = redirect ? `${targetPath}?redirect=${redirect}` : targetPath;
}

/**
 *
 * 兼容react-router-dom的新窗口打开；
 *
 * 在设置有basename的情况下手动拼接正确的href喂给window.open费时费力，且需要处理window.open的各项安全；
 * 使用Link则可免掉大部分手动安全设置，天生兼容basename
 *
 * @example
 * ```
 * const [ele, open] = useOpenWindow()
 *
 * open({ to: '' })
 *
 * return (ele);
 * ```
 */
export function useOpenWindow<S = unknown>(): [
  React.ReactNode,
  (nextProps: PropsWithoutRef<LinkProps<S>>) => void,
] {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [props, setProps] = useState<PropsWithoutRef<LinkProps<S>>>({ to: '' });
  const ele: ReactNode = useMemo(
    () => (
      <div style={{ display: 'none' }}>
        <Link
          {...props}
          ref={linkRef}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noopener noreferrer"
        />
      </div>
    ),
    [props],
  );
  const openIt = useRef(false);

  const open = useCallback((nextProps: PropsWithoutRef<LinkProps<S>>) => {
    openIt.current = true;
    setProps(nextProps);
  }, []);

  useEffect(() => {
    const node = linkRef.current;
    if (node && openIt.current && props) {
      openIt.current = false;
      node.click();
    }
  }, [props]);

  return [ele, open];
}

export function getCurrentPagePath() {
  const { pathname, search } = window.location;
  return `${pathname}${search}`;
}

/**
 * 获取 query 参数
 *
 * @export
 * @param {string} [qs]
 * @return {*}
 */
export function getQueryParams(qs?: string) {
  let queryStr = qs || window.location.search;
  queryStr = queryStr.replace(/\+/g, ' ');

  const params: Record<string, string> = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;
  let tokens;

  while ((tokens = re.exec(queryStr))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}
