import React, { useReducer, useEffect, useState } from 'react';
import { MENU_DATA } from 'maker/config';
import { getUserInfo } from 'maker/services/zhb/auth';
import { getAuthToken } from 'maker/utils/session';
// import { getCompleteIdArray } from 'maker/routes/AccountManage/menuUtil';
import { goLoginPage } from 'maker/utils/router';
import { Menu } from 'maker/utils/menu';
import Context from './context';
import { useHistory } from 'react-router-dom';
import { useRefFunction } from '@tencent/glue-react-use';
import { getMenuDataAsync } from '../components/BeaconChart';
import { getThirdCorpInfo, updateCorpId } from './util';
import { message } from '@tencent/adp-combine-ui';
import { BEACON_CONFIG_LIST } from '../components/BeaconChart/config';
import { useMount } from 'ahooks';
import { adminAegisIns } from '../common/aegis';

type ACTIONTYPE = { type: 'setData' | 'refreshUserInfo'; data: any };

const reducer = (state: any, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        ...action.data,
      };
    case 'refreshUserInfo':
      return {
        ...state,
        refreshUserInfo: state.refreshUserInfo + 1,
      };
    default:
      return state;
  }
};

const GlobalDataProvider = (props: any) => {
  const { children } = props;

  const initState = {
    MENU: new Menu(),
    refreshUserInfo: 1,    // 用来刷新取用户信息
    nickName: '',
    phone: '',
    uid: '',
    fid: '',
    roleName: '',
    authList: [],          // 有权限的菜单id
    isAdmin: false,        // 是否管理员
    history: useHistory(),
    selectMenuIndex: -1,
    currUrlPath: '',
    corpInfo: {            // 第三方选中企微主体信息
      loading: true,
      id: '',
      name: '',
      isConversation: false, // 是否接入会话存档
      thirdCorpList: [],
    },
  };

  // 初始化reducer
  const [state, dispatch] = useReducer(reducer, initState);

  // 为什么一定要loading: 拉取鉴权后，再跳转router，确保不会404
  const [loading, setLoading] = useState(true);

  // 获取登陆用户信息
  const fetchUserInfo = useRefFunction(async (): Promise<void> => {
    // 不是首页，就跳转上一页
    const isGoLogin = window.location.pathname !== '' && window.location.pathname !== '/';

    const token = getAuthToken();
    if (!token) {
      if (isGoLogin) {
        goLoginPage(state.history);
      }
      return;
    }

    // 获取用户登录鉴权接口信息
    const res = await getUserInfo(token);
    if (res.code !== 200) {
      if (isGoLogin) {
        goLoginPage(state.history);
        return;
      }
      return;
    }
    // userType: 1商家  2子账号
    const { data: { nickName, phone, zid, uid, Identitys, menuList, userType, uidIsBind, accountName, fid } } = res;

    let rName = '';
    if (Identitys && Identitys.length > 0) {
      rName = Identitys[0].roleName;
    }
    let uiUidIsBind = uidIsBind;
    if (uiUidIsBind === undefined) {
      uiUidIsBind = true;
    }

    const isAdmin = menuList.length === 0 || (menuList.length === 1 && menuList[0] === 0); // 只有1个元素0 也为管理员

    // 拉取三方列表
    const corpInfo = await getThirdCorpInfo();
    const nextMenuData = await getMenuDataAsync({
      corpId: corpInfo.id,
      prevMenuData: MENU_DATA,
      configList: BEACON_CONFIG_LIST,
    });

    state.MENU.initMenuData(nextMenuData, menuList, isAdmin);

    // 监控上报uin，用于统计uv
    adminAegisIns.setConfig({
      uin: corpInfo.id,
    });

    dispatch({
      type: 'setData',
      data: {
        nickName,
        phone,
        fid,
        zid,
        uid,
        roleName: rName,
        authList: menuList,
        isAdmin,
        userType,
        uidIfBind: uiUidIsBind,
        accountName,
        corpInfo,
      },
    });
  });

  const switchCorp = useRefFunction(async (corpId: string): Promise<void> => {
    message.success('切换成功');
    updateCorpId(corpId);

    // 监控上报uin，用于统计uv
    adminAegisIns.setConfig({
      uin: corpId,
    });

    if (!state.history.location.pathname.startsWith('/scrm/setting')) {
      window.location.reload();
    }
  });

  useMount(() => {
    if (window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/normal')) {
      setLoading(false);
      return;
    }

    fetchUserInfo().finally(() => {
      requestAnimationFrame(() => {
        setLoading(false);
      });
    });
  });

  // 初始化之后调用刷新权限
  useEffect(() => {
    if (!loading && state.refreshUserInfo > 1) {
      fetchUserInfo();
    }
  }, [loading, state.refreshUserInfo, fetchUserInfo]);

  return <Context.Provider value={{ state, dispatch, switchCorp }}>
    {!loading && children}
  </Context.Provider>;
};

export default GlobalDataProvider;
