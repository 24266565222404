/**
 * @file request.ts
 * @author markJia(markjia@tencent.com)
 */

import axios, { AxiosRequestConfig, isCancel, CancelToken } from 'axios';
import { ZHB_API_URLS, ZhbApiType, ZhbApiResp } from './api';
import { message } from '@tencent/adp-combine-ui';
import { updateZhbCommonHeader } from '../../../../common/requestHelper/util';

export const NETWORK_ERR_MSG = '网络异常，请稍后重试';

declare module 'axios' {
  export interface AxiosRequestConfig {
    // custom properties
    hideTip?: boolean | number[];
    errorMessage?: string;
  }
}

export const instance = axios.create({
  baseURL: ZhbHost,
  timeout: 10 * 1000,
  timeoutErrorMessage: '服务超时，请稍后重试',
});

instance.interceptors.request.use(
  requestConfig => updateZhbCommonHeader(requestConfig),
  (err) => {
    message.error(err.message || '服务异常');
    throw err;
  },
);

const showErrorToast = (config?: RequestConfig<any>): void => {
  if (config?.hideTip) {
    return;
  }

  let errMsg = config?.errorMessage || NETWORK_ERR_MSG;
  if (errMsg === 'Network Error') {
    errMsg = NETWORK_ERR_MSG;
  }

  message.error(errMsg);
};

instance.interceptors.response.use((response) => {
  if (isCancel(response)) {
    throw response;
  }

  if (response.config.responseType === 'blob') {
    try {
      return Promise.resolve(response.data);
    } catch (err) {}
  }

  let errMsg = '';
  if (response.status > 300 && response.status !== 304) {
    errMsg = response.statusText || '服务异常';
  }

  const { code, data, msg } = response.data || {};

  if (code !== 200 && code !== 0 && code !== 'Success') {
    errMsg = code + (msg || '服务异常，请稍后重试');
  }

  if (errMsg) {
    response.config.errorMessage = errMsg;
    const { hideTip } = response.config;
    if (Array.isArray(hideTip) ? !hideTip.includes(code) : !hideTip) {
      showErrorToast(response.config);
    }
    throw response.data;
  }

  return data;
}, (err) => {
  if (isCancel(err)) {
    throw err;
  }

  if (err.config.hideTip !== true) {
    const errMsg = err.config?.errorMessage || err?.message || '服务异常';
    if (err.config) {
      err.config.errorMessage = errMsg;
    }
    showErrorToast(err.config);
  }

  throw err;
});

interface RequestConfig<T extends ZhbApiType> extends Omit<AxiosRequestConfig, 'url'> {
  apiType?: T;
}

export function request<T extends ZhbApiType>(
  config: RequestConfig<T>): Promise<ZhbApiResp[T]>;

export function request<D = any>(config: AxiosRequestConfig): Promise<D>;

export function request(config: any): any {
  const { apiType, method = 'post' } = config;
  if (apiType) {
    const url: string = ZHB_API_URLS[apiType as ZhbApiType];
    return instance.request({ ...config, method, url });
  }

  return instance.request({ ...config, method });
};

export { CancelToken };
