import React, { useEffect, useState } from 'react';
import { Modal, message } from '@tencent/adp-combine-ui';
import { Input } from 'maker/components/zhb/adp';
import { FormItem } from 'maker/components/zhb/container';
import { modifyName } from 'maker/services/zhb/accountManage';
import { useGlobalContext } from '@/maker/hooks/useGlobalStore';

interface ModifyPasswordDialogProps {
  show: boolean;
  onHide: any;
}

const ModStoreName = (props: ModifyPasswordDialogProps) => {
  const { state, dispatch } = useGlobalContext();
  const { nickName } = state;
  const [storeName, setStoreName] = useState<string>(nickName);
  const onOk = async () => {
    if (!storeName) {
      message.warning('商家名称不能为空');
      return;
    }
    const res = await modifyName({
      name: storeName,
    });
    if (res?.response && res.response.code === 200) {
      message.success('修改成功');
      dispatch({
        type: 'setData',
        data: { nickName: storeName },
      });
      props.onHide();
    } else {
      // message.warning('修改失败');
    }
  };

  useEffect(() => {
    setStoreName(nickName);
  }, [nickName]);

  return (<>
    <Modal
      title="修改商家名称"
      show={props.show}
      onOk={onOk}
      onCancel={() => {
        setStoreName(nickName);
        props.onHide();
      }}
      width={540}
    >
      <div>
        <FormItem label="商家名称" require>
          <Input width={380} value={storeName} placeholder=''
            maxLength={15}
            onChange={(v: string) => {
              setStoreName(v.trim());
            }} />
        </FormItem>
      </div>
    </Modal>
  </>);
};

export default ModStoreName;
