/**
 * @file util.ts
 * @author markJia(markjia@tencent.com)
 */

import { InternalAxiosRequestConfig } from 'axios';
import { getAuthToken } from 'maker/utils/session';
import { getCsrfToken } from '@tencent/sr-app';
import { message } from '@tencent/adp-combine-ui';

/**
 * 白名单
 */
export const API_WHITE_LIST = [
  `${ZhbHost}/userb/api/v1/user/manage/core/login`,
  `${ZhbHost}/api/fengye/brand/page/user/login`,
  `${ZhbHost}/api/fengye/brand/page/user/registered`,
  `${ZhbHost}/userb/api/v1/user/verification/code/verifyPhoneCode`,
  `${ZhbHost}/userb/api/v1/user/verification/code/getPhoneCode`,
];

export const updateZhbCommonHeader = (requestConfig: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const newConfig = { ...requestConfig };
  const { url } = newConfig;

  // 麦宝token
  const authToken = getAuthToken();


  if (!url) {
    const errMsg = '系统异常，请稍后重试！';
    message.info(errMsg);
    throw new Error('系统异常，请稍后重试！');
  }

  // console.log(`authToken:${authToken}`);
  newConfig.headers.token = authToken;
  // newConfig.headers.common['token'] = 'fb27a681-2db0-4b5f-86a9-14d0b6d59d97';

  const csrfToken = getCsrfToken(authToken);
  if (authToken && !API_WHITE_LIST.includes(url)) {
    // config.headers.common['auth-token'] = authToken;  // NOCC: no-param-reassign(其他: 老代码)
    newConfig.headers['csrf-token'] = csrfToken;  // NOCC: no-param-reassign(其他: 老代码)
    //  'c7c6cc1faca52b257a0db6002ffda84196a61e1cfc79cda9d15df1731f67f54e';
  }

  return newConfig;
};
