import React, { CSSProperties } from 'react';
import styles from './formItem.less?local';
import classnames from 'classnames';

interface ItemContainerProps {
  label?: string | JSX.Element;
  require?: boolean;
  children: any;
  inline?: boolean;
  style?: any;
  labelClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  className?: string;
}

const ItemContainer = (props: ItemContainerProps) => {
  const { inline, labelStyle, contentStyle, className, labelClassName } = props;
  const labelCls = classnames({
    [styles.label]: true,
    [styles.disabled]: props.disabled,
  }, labelClassName);


  const rootCls = classnames({
    [styles.item]: true,
    [styles.inline]: inline,
  }, className);

  return (
    <div
      className={rootCls}
      style={props.style ? props.style : {}}>
      {typeof (props.label) !== 'undefined'
      && <div className={labelCls} style={labelStyle}>
        {props.label ? props.label : ''}
        {props.require && (<span className={styles.require}>*</span>)}
      </div>}
      <div className={`${styles.text} ${props.textClassName ? props.textClassName : ''}`} style={contentStyle}>
        {props.children}
      </div>
    </div>
  );
};

export default ItemContainer;
