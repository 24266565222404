import React, { useEffect, useState } from 'react';
import Loadable from 'react-loadable';

function Loading() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return null;
  // return loading && <div>加载中...</div>;
}

export const Dynamic = ({ component, models: m = () => [], webpack, modules }) => {
  const moduleInfo = {};
  webpack && (moduleInfo.webpack = webpack);
  modules && (moduleInfo.modules = modules);

  return Loadable.Map({
    loading: Loading,
    loader: {
      component,
      models: () => Promise.all([...m()]),
    },
    render(loaded, props) {
      const { models } = loaded;
      const { store, ..._props } = props;

      if (store) {
        models.forEach(model => store.injectModel(model.default.namespace, model.default));
      }

      const Comp = loaded.component.default;
      return <Comp {..._props} />;
    },
    // ...moduleInfo
    modules,
    webpack,
  });
};

export default Dynamic;
