/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”。
 */

import React from 'react';
import SubApp from 'maker/routes/SubApp/wujie';
import WithSubApp from 'maker/routes/SubApp/wrapper';
import { Client, Cooperate, Plan, User, UserGroup } from '@tencent/adp-icons-react';
import dynamic from 'store/dynamic';
import {
  IconOpsTool,
} from 'maker/components/Icon/icon';
import { SCRM_MUNU_ID } from './const';

export const ScrmData = {
  id: SCRM_MUNU_ID,
  name: '运营工具箱',
  icon: IconOpsTool,
  children: [
    {
      id: 403,
      name: '客户管理',
      icon: () => <Client size={18} />,
      children: [{
        id: 29,
        name: '客户列表',
        children: [{
          id: 323,
          key: 'customlist',
          name: '客户列表',
          url: '/scrm/qv-scrm/customer-manage/customer/list',
          component: WithSubApp(SubApp),
        }, {
          id: 325,
          name: '流失提醒',
          url: '/scrm/qv-scrm/customer-manage/customer/loss-reminder',
          component: WithSubApp(SubApp),
        }],
      },
      {
        id: 408,
        parentId: 403,
        name: '标签管理',
        children: [
          {
            id: 343,
            name: '标签列表',
            url: '/customer/customerTags',
            component: dynamic({
              component: () => import('maker/routes/Scrm/customerTags/index'),
            }),
          },
          /* {
            id: 336,
            name: '话术模版标签',
            url: '/scrm/qv-scrm/session-archive/retrieval/overall',
            component: WithSubApp(SubApp),
          },*/
          {
            id: 345,
            name: '企微同步',
            url: '/scrm/qv-scrm/customer-manage/customer/tags',
            component: WithSubApp(SubApp),
          },
        ],
      },
      {
        id: 346,
        name: '',
        url: '/customerTags/wordTagDetail',
        isHide: true,
        component: dynamic({
          component: () => import('maker/routes/Scrm/customerTags/wordTagDetail'),
        }),
      },
      {
        id: 30,
        name: '客户群管理',
        url: '',
        children: [{
          id: 329,
          name: '客户群列表',
          url: '/scrm/qv-scrm/customer-manage/group/list',
          component: WithSubApp(SubApp),
        }, {
          id: 330,
          name: '客户群标签',
          url: '/scrm/qv-scrm/customer-manage/group/tags',
          component: WithSubApp(SubApp),
        },
        ],
      },
      ],
    },
    {
      id: 28,
      name: '员工管理',
      icon: () => <User size={18} />,
      children: [
        {
          id: 32,
          name: '话术管理',
          url: '',
          children: [
            {
              id: 33,
              name: '话术模板',
              url: '/dailogTplMange',
              component: dynamic({
                component: () => import('maker/routes/Conversation/dialogTemplate'),
              }),
            },
            {
              id: 34,
              name: '员工消息',
              url: '/staffMessage',
              component: dynamic({
                component: () => import('maker/routes/Conversation/staffMessage'),
              }),
            },
          ],
        },
        {
          id: 402,
          name: '会话质检',
          url: '',
          children: [{
            id: 335,
            name: '客户检索',
            url: '/scrm/qv-scrm/session-archive/retrieval/customer',
            component: WithSubApp(SubApp),
          }, {
            id: 337,
            name: '员工检索',
            url: '/scrm/qv-scrm/session-archive/retrieval/staff',
            component: WithSubApp(SubApp),
          }, {
            id: 338,
            name: '离职人员检索',
            url: '/scrm/qv-scrm/session-archive/retrieval/withdrawn',
            component: WithSubApp(SubApp),
          }],
        },
        {
          id: 404,
          name: '员工继承',
          url: '',
          children: [{
            id: 331,
            name: '通讯录',
            url: '/scrm/qv-scrm/enterprise-mgt/address-list',
            component: WithSubApp(SubApp),
          }, {
            id: 327,
            name: '在职继承',
            url: '/scrm/qv-scrm/customer-manage/customer/incumbent-succ',
            component: WithSubApp(SubApp),
          }, {
            id: 328,
            name: '离职继承',
            url: '/scrm/qv-scrm/customer-manage/customer/inheritance',
            component: WithSubApp(SubApp),
          },
          ],
        },
      ],
    },
    {
      id: 25,
      name: '营销获客',
      url: '',
      icon: () => <Cooperate size={18} />,
      children: [{
        id: 312,
        name: '员工活码',
        url: '/scrm/qv-scrm/customer-marketing/customer/staff-code',
        component: SubApp,
      }, {
        id: 313,
        name: '员工欢迎语',
        url: '/scrm/qv-scrm/customer-marketing/customer/staff-welcome',
        component: SubApp,
      }, {
        id: 314,
        name: '群活码',
        url: '/scrm/qv-scrm/customer-marketing/group/group-qrcode',
        component: SubApp,
      }, {
        id: 315,
        name: '新客自动拉群',
        url: '/scrm/qv-scrm/customer-marketing/group/group-auto-pull',
        component: SubApp,
      }, {
        id: 316,
        name: '老客标签建群',
        url: '/scrm/qv-scrm/customer-marketing/group/group-tag-pull',
        component: SubApp,
      },
      ],
    }, {
      id: 26,
      name: '群发助手',
      url: '',
      icon: () => <UserGroup size={18} />,
      children: [{
        id: 317,
        name: '个人群发',
        url: '/scrm/qv-scrm/customer-marketing/mass-send-tools/customer',
        component: SubApp,
      }, {
        id: 318,
        name: '群发朋友圈',
        url: '/scrm/qv-scrm/customer-marketing/mass-send-tools/friends',
        component: SubApp,
      }, {
        id: 319,
        name: '社群群发',
        url: '/scrm/qv-scrm/customer-marketing/mass-send-tools/group',
        component: SubApp,
      }],
    }, {
      id: 27,
      name: '营销计划',
      url: '',
      icon: () => <Plan size={18} />,
      children: [{
        id: 320,
        name: '节点营销',
        url: '/scrm/qv-scrm/customer-marketing/plan/personal-sop',
        component: SubApp,
      }, {
        id: 321,
        name: '导购任务下发',
        url: '/scrm/qv-scrm/customer-marketing/follow-up/customers',
        component: SubApp,
      }],
    },
  ],
};
