import React from 'react';
import { Spin } from '@tencent/adp-combine-ui';
import styles from './container.less?local';
import classNames from 'classnames';

interface ContainerProps {
  className?: string;
  title: string | JSX.Element;
  titleClassName?: string;
  children: any;
  loading?: boolean;
  style?: any;
  wrapperStyle?: any;
  hasBG?: boolean;
  hasSub?: boolean;
  more?: string | JSX.Element;
  subWrapperCls?: string;
  contentClassName?: string;
}

const Container = (props: ContainerProps) => {
  const cls = classNames(
    props.className || '',
    {
      [styles.body_bg]: props.hasBG,
      [styles.body]: !props.hasBG,
      [styles.has_sub]: props.hasSub,
    },
  );

  const subWrapperCls = classNames({
    [styles['sub-wrapper']]: true,
    [props.subWrapperCls || '']: !!props.subWrapperCls,
  });

  const contentCls = classNames(
    props.contentClassName || '',
    styles.content,
  );
  return (
    <div className={cls} style={props.wrapperStyle ? props.wrapperStyle : {}}>
      <div className={subWrapperCls}>
        {props.loading && <div className={styles.loading}><Spin /></div>}
        {props.more && <div className={styles.more}>{props.more}</div>}
        {props.title
          ? <div className={`${styles.title} ${props.titleClassName ? props.titleClassName : ''}`}>{props.title}</div>
          : null
        }
        <div className={contentCls} style={props.style ? props.style : {}}>{props.children}</div>
      </div>
    </div>
  );
};

export default Container;
