import React, { useState, useEffect, useCallback } from 'react';
// import { Popover } from '@tencent/adp-combine-ui';
// import { IconExpanseUp, IconExpanseDown } from 'maker/config/menu/icon';
// import Expanse from 'maker/components/Expanse';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import { Menu } from '@tencent/adp-combine-ui';
import styles from './header.less?local';
import classNames from 'classnames';
import { zhbAdminActionReporterIns } from '../utils/actionReport';
import { reportAdminAegisEvent } from '../common/aegis';

interface MenuModuleProps {
  path: string;
  history: any;
  selectIndex: number;
  onFold: any;
  isCollapsed: boolean;
}

type TypeMenuItem = {
  id: number;
  name: string;
  url: string;
  children: Array<any>;
  icon: any;
};


const urlIsSelected = (url: string, level: number, path: string) => {
  if (!url) {
    return false;
  }
  const tmp = url.split('/');
  const selected = url === path || `${url}/` === path || (path.indexOf(url) !== -1 && level > 1 && tmp.length > 2);
  return selected;
};

// 根据ID 获取url
const geMenuById = (id: string | number, menuData: any) => {
  const menuId = Number(id);
  for (const item of menuData) {
    if (item.id === menuId) {
      return item;
    }
    if (item.children) {
      for (const child of item.children) {
        if (child.id === menuId) {
          if (child.children && child.children.length > 0) {
            return child.children[0];
          }
          return child;
        }
        if (child.children) {
          for (const subChild of child.children) {
            if (subChild.id === menuId) {
              return subChild;
            }
          }
        }
      }
    }
  }
  return null;
};

// 根据url获取选中菜单数据
const geMenuByUrl = (path: string, menuData: any) => {
  for (const item of menuData) {
    if (urlIsSelected(item.url, 0, path)) {
      return item;
    }
    if (item.children) {
      for (const child of item.children) {
        if (urlIsSelected(child.url, 1, path)) {
          child.parentId = item.id;
          return child;
        }
        if (child.children) {
          for (const subChild of child.children) {
            if (urlIsSelected(subChild.url, 2, path)) {
              child.parentId = item.id;
              return child;
            }
          }
        }
      }
    }
  }
  return null;
};

const MenuModule = (props: MenuModuleProps) => {
  const { MENU, isAdmin, corpInfo, phone } = useGlobalStore();
  const { menuData, fullAuthList } = MENU;

  // 判断是否有权限
  // 1. 管理员忽略权限
  // 2. 有分配权限的
  const hasPower = useCallback((id: number) => isAdmin || fullAuthList.includes(id), [isAdmin, fullAuthList]);

  // 选中的菜单
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const getFirstMenuIds = useCallback(() => {
    const ids = [];
    for (const item of menuData) {
      if (item.children) {
        for (const child of item.children) {
          ids.push(`${child.id}`);
        }
      }
    }
    return ids;
  }, [menuData]);

  const [openKeys, setOpenKeys] = useState<any[]>(getFirstMenuIds());

  // 获取默认展开的菜单
  const isOver = props.isCollapsed && mouseOver;
  const isFolded = props.isCollapsed && !mouseOver;
  const mewOpenKeys = isFolded ? [] : openKeys;

  const onFold = () => {
    const flag = !props.isCollapsed;
    props.onFold(flag);
  };

  const changeMenu = (menu: any) => {
    const data = geMenuById(menu.key, menuItems);
    if (data) {
      setSelectedKeys([menu.key]);
      if (data.url) {
        props.history.push(data.url);
      } else if (data.children) {
        // 四级菜单
        for (const child of data.children) {
          if (hasPower(child.id)) {
            props.history.push(child.url);
            return;
          }
        }
      }
    }
  };

  // 展开收起
  const onOpenChange = (openKeys: any) => {
    if (isOver || !props.isCollapsed) {
      setOpenKeys(openKeys);
    }
  };

  useEffect(() => {
    setMenuItems(menuData[props.selectIndex].children);

    // 初始化默认选中菜单数据
    const menu = geMenuByUrl(props.path, menuData[props.selectIndex].children);
    if (menu) {
      setSelectedKeys([`${menu.id}`]);
      zhbAdminActionReporterIns.report({
        corp_id: corpInfo?.id || 'unknown',
        scene_id: 'web_admin',
        phone,
        trace_url: window.location.href,
        events: [
          {
            ts: Date.now() / 1000 | 0,
            action_type: 'menu_click',
            data: {
              menu_id: menu.id,
              menu_name: menu.name,
            },
          },
        ],
      });

      reportAdminAegisEvent({
        name: 'menu_click',
        data: {
          corp_id: corpInfo?.id || 'unknown',
          corp_name: corpInfo.name,
          menu_id: menu.id,
          menu_name: menu.name,
        },
      });
    }
  }, [props.selectIndex, props.path, menuData, phone, corpInfo?.id]);

  const { size = 'default' } = menuData[props.selectIndex];
  const menuCls = classNames({
    ['sidder-menu--large']: size === 'large',
  });

  return (
    <div
      style={{
        position: isOver ? 'relative' : 'static',
      }}
    >
      <div
        className={styles['menu-body-wrapper']}
        style={{
          position: isOver ? 'absolute' : 'static',
          background: isOver ? 'linear-gradient(180deg, #E9EDF5 0%, #FFF 100%)' : 'none',
          zIndex: 100000,
        }}
      >
        <div className={styles['split-line-top']}></div>
        <div
          className={props.isCollapsed ? styles['menu-wrapper-fold'] : styles['menu-wrapper']} onMouseEnter={() => {
            setMouseOver(true);
          }} onMouseLeave={() => {
            setMouseOver(false);
          }}>
          <Menu mode="inline"
            style={{
              width: isFolded ? 40 : 154,
              // transition: isOver ? '0.5s ease' : 'all 0 ease 0',
            }}  // props.isCollapsed ? undefined :
            inlineCollapsed={isFolded}
            onSelect={changeMenu}
            selectedKeys={selectedKeys}
            openKeys={mewOpenKeys}
            onOpenChange={onOpenChange}
            className={menuCls}
          >
            {
              menuItems.map((menu: TypeMenuItem) => {
                const show = hasPower(menu.id);
                return show
                  ? (
                    <>
                      {
                        menu.url
                          ? (
                            <Menu.Item
                              onClick={() => {
                                reportAdminAegisEvent({
                                  name: 'menu_click',
                                  data: {
                                    corp_id: corpInfo?.id || 'unknown',
                                    corp_name: corpInfo.name,
                                    menu_id: menu.id,
                                    menu_name: menu.name,
                                  },
                                });

                                zhbAdminActionReporterIns.report({
                                  corp_id: corpInfo?.id || 'unknown',
                                  scene_id: 'web_admin',
                                  phone,
                                  trace_url: window.location.href,
                                  events: [
                                    {
                                      ts: Date.now() / 1000 | 0,
                                      action_type: 'menu_click',
                                      data: {
                                        menu_id: menu.id,
                                        menu_name: menu.name,
                                      },
                                    },
                                  ],
                                });
                              }}
                              key={String(menu.id)} icon={<menu.icon />}>{menu.name}</Menu.Item>
                          )
                          : (
                            <Menu.SubMenu
                              key={String(menu.id)}
                              icon={<menu.icon />}
                              title={<span style={{ marginLeft: 4 }}>{menu.name}</span>}
                            >
                              {menu.children.map((item: any) => {
                                const show = hasPower(item.id) && !item.isHide;
                                return show
                                  ? (
                                    <Menu.Item
                                      onClick={() => {
                                        reportAdminAegisEvent({
                                          name: 'menu_click',
                                          data: {
                                            corp_id: corpInfo?.id || 'unknown',
                                            corp_name: corpInfo.name,
                                            menu_id: menu.id,
                                            menu_name: menu.name,
                                          },
                                        });
                                      }}
                                      key={`${item.id}`
                                      }>{item.name}</Menu.Item>
                                  )
                                  : null;
                              })}
                            </Menu.SubMenu>
                          )
                      }
                    </>
                  )
                  : null;
              })
            }
          </Menu>
        </div>
        <div className={styles['split-line-bottom']}></div>
        {!isOver && (<div className={styles['fold-btn']} onClick={onFold}></div>)}
      </div>
    </div>
  );
};

export default MenuModule;
