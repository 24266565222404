import { Request } from '@common/requestHelper';
import { ptApi } from 'maker/config/privateTraffic/config';
import { SaveRequest } from 'maker/types/models/privateTraffic/wecomOrderSave';
import { ListRequest } from 'maker/types/models/privateTraffic/wecomOrderList';
import { AuthoritylistRequest } from 'maker/types/models/privateTraffic/wecomAuthorityList';
import { DetailRequest } from 'maker/types/models/privateTraffic/wecomCustomerDetail';
import { ListRequest as MomentList } from 'maker/types/models/privateTraffic/wecomMomentList';

// export const queryWeComAuthState = async (params: any) => Request.post(ptApi.queryWeComAuthState, params);

// 屏蔽公共报错
export async function queryWeComAuthState(params: any = {}) {
  return await Request.post(ptApi.queryWeComAuthState, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });
}

export const queryWecomAuthoritylist
  = async (params: AuthoritylistRequest) => Request.post(ptApi.queryWecomAuthoritylist, params);

export const wecomOrderSave = async (params: SaveRequest) => Request.post(ptApi.wecomOrderSave, params);

export const wecomOrderList = async (params: ListRequest) => Request.post(ptApi.wecomOrderList, params);

export const wecomMomentList = async (params: MomentList) => Request.post(ptApi.wecomMomentList, params);

export const wecomCustomerDetail = async (params: DetailRequest) => Request.post(ptApi.wecomCustomerDetail, params);
