import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Tabs } from '@tencent/adp-combine-ui';
import { Container } from 'maker/components/zhb/container';
import classNames from 'classnames';
import cls from './subMenu.local.less';

interface MenuModuleProps {
  menuData: any[];
  children: any;
  history: any;
  selectMenuId: number;
  selectMenuName: string;
}

const SubMenuModule = (props: MenuModuleProps) => {
  const { selectMenuId, menuData, children, history } = props; // selectMenuName
  const [selectedMenuId, setSelectedMenuId] = useState<number>(selectMenuId);

  const onValueChange = useCallback((id: number) => {
    for (const menu of menuData) {
      if (id === menu.id && menu.url) {
        history.push(menu.url);
        setSelectedMenuId(menu.id);
      }
    }
  }, [menuData, setSelectedMenuId, history]);

  const subWrapperCls = useMemo(() => (menuData.find(item => item.id === selectMenuId) || {}).subWrapperCls || '', [menuData, selectMenuId]);

  useEffect(() => {
    setSelectedMenuId(selectMenuId);
  }, [selectMenuId]);

  const tabContentCls = classNames({
    [cls.subMenuContent]: true,
    'page-container-sub-menu-content': true,
  });

  return (
    <Container subWrapperCls={subWrapperCls} title="" hasSub>
      <Tabs
        value={selectedMenuId}
        onValueChange={onValueChange}
        style={{ display: 'flex', alignItems: 'center', marginLeft: -24 }}
        headerStyle={{ flex: 'auto', padding: 0, margin: '0 24px' }}
        headerBorder={false}
        contentStyle={{ display: 'none' }}
      >
        {menuData.map((item: any) => (item.name && !item.isHide ? <Tabs.Item
          key={`submenu-${item.id}`}
          id={item.id}
          title={item.name} children={null} />
          : null))}
      </Tabs>
      <div className={tabContentCls}>
        {children}
      </div>
    </Container>
  );
};

export default SubMenuModule;
