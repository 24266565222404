/**
 * @file hooks.ts
 * @author markJia(markjia@tencent.com)
 */

import React from 'react';
import { MenuItem } from '../../config/menu/interface';
import { request } from '../../services/zhb/request';
import { MenuIdTree } from '../../config/menu/const';
import { BeaconChart } from './BeaconChart';
import { BeaconConfigListItem } from './config';
import { message } from '@tencent/spaui';
import { COMMON_INTERNAL_ERROR_MSG } from '../../services/zhb/const/error';


interface GetBeaconConfigByModIdOption {
  corpId: string;
  modId?: string | null;
}

interface GetBeaconConfigByModIdDataRet {
  corpId: string;
  pageId: number;
  title: string;
}

const getBeaconConfigByModId = (option: GetBeaconConfigByModIdOption): Promise<GetBeaconConfigByModIdDataRet[] | null> => {
  const { corpId, modId } = option;

  const params: GetBeaconConfigByModIdOption = {
    corpId,
  };

  if (modId) {
    params.modId = modId;
  }

  return request({
    url: '/api/marketing/wecom/lighthouse/corp/links',
    method: 'post',
    data: params,
  });
};

const removeMenuByIds = (prevMenuData: MenuItem[], needRmMenuIds: number[]): MenuItem[]  => {
  const list: MenuItem[] = [];

  prevMenuData.forEach((item) => {
    if (item.id && needRmMenuIds.includes(item.id)) {
      return;
    }

    const nextItem = { ...item };
    if (nextItem.children) {
      nextItem.children = removeMenuByIds(nextItem.children, needRmMenuIds);
    }

    list.push(nextItem);
  });

  return list;
};

interface UpdateMenuWithBeaconOption {
  prevMenuData: MenuItem[];
  beaconConfMap: Record<number, GetBeaconConfigByModIdDataRet[]>;
  corpId: string;
  configList: BeaconConfigListItem[];
}

const updateMenuWithBeacon = (option: UpdateMenuWithBeaconOption): MenuItem[] => {
  const { prevMenuData, beaconConfMap, corpId, configList } = option;
  const list: MenuItem[] = [];
  prevMenuData.forEach((item) => {
    const nextItem: MenuItem = { ...item };

    if (nextItem.children) {
      nextItem.children = updateMenuWithBeacon({
        prevMenuData: nextItem.children,
        beaconConfMap,
        corpId,
        configList,
      });
    }

    if (nextItem.id && beaconConfMap[nextItem.id]) {
      const { id: parentMenuId } = nextItem;
      const beaconConf = configList.find(item => item.injectMenuId === parentMenuId);
      if (!beaconConf) {
        message.error(`${COMMON_INTERNAL_ERROR_MSG}: 读取报表配置失败`);
        return;
      }
      const beaconChildren = beaconConfMap[nextItem.id].map((conf, index) => {
        // 历史原因，经营报表索引多100
        const baseLevel = nextItem.id === MenuIdTree.MARKETING_MENU_ID__REPORT ? 1000 : 100;
        const baseOffset = nextItem.id === MenuIdTree.MARKETING_MENU_ID__REPORT ? 100 : 0;

        const menuId = parentMenuId * baseLevel + baseOffset + index + 1;
        return {
          id: menuId,
          name: conf.title,
          url: `${beaconConf.urlPrefix}/${menuId}`,
          subWrapperCls: beaconConf.subWrapperCls,
          component: () => <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <BeaconChart corpId={corpId} pageId={conf.pageId} />
          </div>,
        };
      });
      nextItem.children = (nextItem.children || []).concat(beaconChildren);
    }

    list.push(nextItem);
  });

  return list;
};

// 灯塔看板开始动态menu

interface GetMenuDataAsyncOption {
  corpId: string;
  prevMenuData: MenuItem[];
  configList: BeaconConfigListItem[];
}

export const getMenuDataAsync = async (option: GetMenuDataAsyncOption): Promise<MenuItem[]> => {
  const { corpId, prevMenuData, configList } = option;

  /**
  * 第1步: 请求到菜单对应的modId的数据
  * 第2步: 找菜单
  * 第3步: 把组件+看板id component 写入menu
  */

  const modIds = configList.map(item => item.modId);
  try {
    const beaconConfList = await Promise.all(modIds.map(modId => getBeaconConfigByModId({ corpId, modId })));
    const needRmMenuIds: number[] = [];
    const needInjectMap: Record<number, GetBeaconConfigByModIdDataRet[]> = {};
    beaconConfList.forEach((beaconConf, idx) => {
      if (beaconConf == null || beaconConf.length === 0) {
        needRmMenuIds.push(configList[idx].injectMenuId);
      } else {
        needInjectMap[configList[idx].injectMenuId] = beaconConf;
      }
    });

    const nextMenuData = removeMenuByIds(prevMenuData, needRmMenuIds);
    return updateMenuWithBeacon({
      prevMenuData: nextMenuData,
      beaconConfMap: needInjectMap,
      corpId,
      configList,
    });
  } catch (err) {
    const needRmMenuIds = configList.filter(item => !!item.noBeaconRemoveMenu).map(item => item.injectMenuId);
    return removeMenuByIds(prevMenuData, needRmMenuIds);
  }
};
