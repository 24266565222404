import React, { useState } from 'react';
import styles from './index.less?local';
import { Popover, message, Tooltip } from '@tencent/adp-combine-ui';
import { ArrowDown, ArrowUp } from '@tencent/adp-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { useGlobalContext } from 'maker/hooks/useGlobalStore';
import Icon from 'maker/assets/layouts/company.svg';
import { SvgInfoCopy } from 'maker/components/Icon/icon';
import { useRefCallback, useRefFunction } from '@tencent/glue-react-use';

const SelectCompotent = () => {
  const { state, switchCorp } = useGlobalContext();
  const { corpInfo } = state;
  const [isShowPover, setShowPover] = useState<boolean>(false);

  // 切换
  const changeCrop = useRefCallback(async (corpId: string) => {
    setShowPover(false);
    switchCorp(corpId);
  });

  // 复制corpId
  const onCopy = () => {
    message.success('CorpID已复制至剪切板');
  };

  const renderDropList = useRefFunction(() => {
    if (!corpInfo.thirdCorpList.length) {
      return <div className={styles['popver-wrapper-selected-subtitle']}>暂无企微主体</div>;
    }

    return (
      <ul>
        <li className={styles['popver-wrapper-selected-subtitle']}>当前企微主体</li>
        <li className={styles['popver-wrapper-selected-title']}>{corpInfo.name}</li>
        <li className={styles['popver-wrapper-selected-info']}>
          <label>CropID：</label>
          <div className={styles['popver-wrapper-selected-info-text']}>{corpInfo.id}</div>
          <CopyToClipboard text={corpInfo.id} onCopy={onCopy}>
            <div className={styles['popver-wrapper-selected-info-copy']}>
              <Tooltip theme="dark" trigger="hover" placement="top" getPopper="复制ID" lazyInit arrow>
                <SvgInfoCopy />
              </Tooltip>
            </div>
          </CopyToClipboard>
        </li>
        <li className={styles['popver-wrapper-select-list']}>
          {corpInfo.thirdCorpList.length > 1 && corpInfo.thirdCorpList.map((item: any) => (item.corpId !== corpInfo.id
            ? <div key={`list-${item.corpId}`}
              title={`CropID：${item.corpId}`}
              onClick={() => {
                changeCrop(item.corpId);
              }}
              className={`${styles['popver-wrapper-select-list-option']}`}>
              {item.corpName}
              <div>切换</div>
            </div>
            : null))}
        </li>
      </ul>
    );
  });

  return (
    <div className={styles['select-wrapper']}>
      <Popover
        trigger="click"
        show={isShowPover}
        placement="bottom-end"
        popoverStyle={{ padding: 0, marginTop: -5 }}
        onVisibleChange={(v: boolean) => setShowPover(v)}
        getPopper={() => <div className={styles['popver-wrapper']}>
          {renderDropList()}
          <div className={styles['popver-wrapper-footer']}>
        找不到想要的主体？还可以
            <Link to="/wecomAuth" style={{ marginLeft: 8 }} target="_blank" onClick={() => setShowPover(false)}>去添加</Link>
          </div>
        </div>}
      >
        <div className={`${styles['selected-item']} ${isShowPover ? styles.selected : ''}`}>
          <span className={styles['selected-item-icon']}>
            <img src={Icon} alt="." />
          </span>
          <span className={styles['selected-item-name']}>
            {corpInfo.name}
          </span>
          <span className={styles['selected-item-drapdown']}>
            {isShowPover
              ? <ArrowUp fill="#4D5869" size={10} />
              : <ArrowDown fill="#4D5869" size={10} />}
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default SelectCompotent;
