import { MenuItem } from '../config/menu/interface';

// 历史逻辑：似乎是为所有节点加上parentId
const loopAllChild = (data: MenuItem[], parentId: number): MenuItem[] => {
  const newMenuItems: MenuItem[] = [];
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      const child: MenuItem[] = loopAllChild(item.children, item.id);
      if (child.length > 0) {
        newMenuItems.push({ ...item, children: child, parentId });
      }
    } else {
      newMenuItems.push({ ...item, parentId });
    }
  }
  return newMenuItems;
};

/**
 * 菜单类
 */
export class Menu {
  // 菜单数据
  public menuData: MenuItem[] = [];

  public fullAuthList: number[] = [];

  private inited = false;

  // 菜单数据初始化
  public initMenuData(data: MenuItem[], apiAuthList: number[], isAdmin: boolean) {
    const isAuth = (id: number) => isAdmin || apiAuthList.includes(id);

    const checkChildrenHasAuth = (data: MenuItem[]): MenuItem[] => {
      const newMenuItems: MenuItem[] = [];

      for (const item of data) {
        const nextItem: MenuItem = { ...item };
        if (item.children && item.children.length > 0) {
          nextItem.children = checkChildrenHasAuth(item.children);
        }

        if (isAuth(nextItem.id) || nextItem.children?.length) {
          newMenuItems.push(nextItem);
        }
      }
      return newMenuItems;
    };

    this.menuData = checkChildrenHasAuth(loopAllChild(data, 0));
    this.fullAuthList = this.calcPowerListWithFullPath(apiAuthList);
    this.inited = true;
    return this;
  }

  // 获取第一个有权限的菜单
  public getFirstMenu(parentIndex?: number): MenuItem | null {
    if (!this.inited) {
      console.error('Menu must initMenuData!');
      return null;
    }

    const loopChild = (data: MenuItem[]): MenuItem | null => {
      for (const item of data) {
        if (item.menuType === 'title') continue;
        if (item.children && item.children.length > 0) {
          return loopChild(item.children);
        } if (item.url && item.name) {
          return item;
        }
      }

      return null;
    };

    if (parentIndex === undefined) {
      return loopChild(this.menuData);
    }

    const parentData = this.menuData[parentIndex];

    if (parentData.children?.length) {
      return loopChild(parentData.children);
    }

    if (parentData.url) {
      return parentData;
    }

    return null;
  }

  // 获取菜单的名称
  public getMenuNames(menuIds: number[]): string[] {
    if (!this.inited) {
      console.error('Menu must initMenuData!');
      return [];
    }
    const menuNames: string[] = [];
    const loopChild = (data: MenuItem[]): void => {
      for (const item of data) {
        if (menuIds.includes(item.id) && item.name) {
          menuNames.push(item.name);
        } else if (item.children && item.children.length > 0) {
          loopChild(item.children);
        }
      }
    };
    loopChild(this.menuData);
    return menuNames;
  }

  /**
   * 跨节点有权限时，应该返回全路径，而不止当前节点。
   */
  private calcPowerListWithFullPath(apiAuthList: number[]): number[] {
    const fullAuthList: number[] = [];

    const getFullAuthList = (menuData: MenuItem[]): boolean => {
      // 有一个子节点标记为true，父级就要
      let childrenHasAuth = false;
      menuData.forEach((item) => {
        if (item.children?.length) {
          childrenHasAuth = getFullAuthList(item.children);
        }

        if (childrenHasAuth || apiAuthList.includes(item.id)) {
          childrenHasAuth = true;
          fullAuthList.push(item.id);
        }
      });

      return childrenHasAuth;
    };

    getFullAuthList(this.menuData);
    return fullAuthList;
  }
};
