import cookie from 'browser-cookies';
import { 
  COOKIE_AUTH_TOKEN, 
  COOKIE_FEATURE_FLAGS,
  FeatureTags, 
} from 'maker/config';


function setCookie(key: string, value: string, expires: undefined | Date) {
  if (!value) return;
  if (expires) {
    return cookie.set(key, value, {
      path: '/',
      domain: '.zhb.qq.com',
      expires,
    });
  }
  return cookie.set(key, value, {
    path: '/',
    domain: '.zhb.qq.com',
  });
}

export function setXiaoMaCookie(params = {}, domain = '.qq.com') {
  const keys = Object.keys(params);

  if (keys.length === 0) {
    return;
  }

  const arr: any = [];
  keys.forEach((key) => {
    arr.push({
      key,
      val: params[key],
    });
  });

  setCustomCookie('zhbXiaomaFilters', JSON.stringify(arr), domain);
}

/**
 * 获取自定义cookie
 * @param key 
 * @returns 
 */
export function getCustomCookie(key: string) {
  const valye = cookie.get(key);
  if (valye) {
    return valye;
  }

  return null;
}

/**
 * 设置自定义cookie
 * @param key 
 * @param value 
 * @param domain 
 * @returns 
 */
export function setCustomCookie(key: string, value: string, domain?: string) {
  if (!value) return;
  return cookie.set(key, value, {
    path: '/',
    domain: domain || '.zhb.qq.com',
  });
}

/**
 * 清除自定义cookie
 * @param key 
 * @param domain 
 */
export function clearCustomCookie(key: string, domain: undefined | string) {
  cookie.set(key, '', {
    path: '/',
    domain: domain || '.zhb.qq.com',
  });
  cookie.erase(key);
}

/**
 * 是否已登录
 */
export function getAuthToken() {
  const authToken = cookie.get(COOKIE_AUTH_TOKEN);
  if (authToken) {
    return authToken;
  }

  return null;
}

/**
 * 写入 cookie: auth-token
 * @param token
 */
export function setAuthToken(token: string, expires: undefined | Date) {
  if (token) {
    setCookie(COOKIE_AUTH_TOKEN, token, expires);
  }
}

/**
 * 清理 cookie: auth-token, saas-id, store-id
 */
export function clearSessions() {
  cookie.set(COOKIE_AUTH_TOKEN, '', {
    path: '/',
    domain: '.zhb.qq.com',
  });
  cookie.erase(COOKIE_AUTH_TOKEN);
}

/**
 * 获取特征开关值
 */
export function getFeature(tagIndex: FeatureTags) {
  const cookieValue = getCustomCookie(COOKIE_FEATURE_FLAGS);
  if (cookieValue && cookieValue.length > tagIndex) {
    return cookieValue[tagIndex] === '1';
  }
  return false;
}

/**
 * 设置特征开关值
 */
export function setFeature(tagIndex: FeatureTags, flag: boolean) {
  let cookieValue: any = getCustomCookie(COOKIE_FEATURE_FLAGS);
  if (!cookieValue || cookieValue.length <= tagIndex) {
    if (!cookieValue) {
      cookieValue = '';
    }
    for (let i=cookieValue.length; i<tagIndex+1; i++) {
      cookieValue += '-';
    }
  }
  const newCookieVaule = [...cookieValue];
  newCookieVaule[tagIndex] = flag ? '1' : '0';
  setCustomCookie(COOKIE_FEATURE_FLAGS, newCookieVaule.join(''));
}

/**
 *  清除特征值
 */
export function clearFeature(tagIndex: FeatureTags) {
  const cookieValue: any = getCustomCookie(COOKIE_FEATURE_FLAGS);
  if (cookieValue && cookieValue.length > tagIndex) {
    const newCookieVaule = [...cookieValue];
    newCookieVaule[tagIndex] = '-';
    setCustomCookie(COOKIE_FEATURE_FLAGS, newCookieVaule.join(''));
  }
}