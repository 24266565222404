import React from 'react';
import styles from './content.less?local';

interface ContainerProps {
  children: any;
  className?: string;
}

const ContentContainer = (props: ContainerProps) => (
  <div className={`${styles.content} ${props.className ? props.className : ''}`}>{props.children}</div>
);

export default ContentContainer;
