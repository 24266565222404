import React, { CSSProperties, useMemo, useState } from 'react';
import { Layout, Empty } from '@tencent/adp-combine-ui';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import TopBottom from './topBottom';
import Menu from './menu';
import ErrorBoundary from './errorBoundary';
import { setFeature, getFeature } from 'maker/utils/session';
import { FeatureTags } from 'maker/config';
import SubMenu from './subMenu';
import { SETTING_MENU_ID } from 'maker/config/menu/const';
import styles from './index.less?local';
const { Sider, Content, Footer } = Layout;

const contentStyle: CSSProperties = {
  textAlign: 'left',
  background: 'none',
  overflow: 'auto',
  marginRight: '0px',
};

const footerStyle: CSSProperties = {
  height: '56px',
  background: 'none',
  fontSize: '12px',
  textAlign: 'center',
  lineHeight: '56px',
  padding: 0,
  color: '#888',
};

const isSelected = (url: string, path: string) => url === path || `${url}/` === path;

const LayoutModule = (props: any) => {
  const [isCollapsed, setCollapsed] = useState<boolean>(getFeature(FeatureTags.MUED_IS_FOLD));
  const { MENU, authList, isAdmin, nickName, history, selectMenuIndex, currUrlPath } = useGlobalStore();
  const { menuData } = MENU;

  const pathUrl = history.location.pathname || currUrlPath;

  // 判断是否有权限
  const isAuth = (id: number) => isAdmin || authList.includes(id);
  let selectIndex = selectMenuIndex;

  // 判断用户是否有访问该菜单的权限
  let hasPower = false;
  // 判断是否需要渲染菜单
  let isInMenu = false;
  let renderSilder =  true;

  // let WrapperCompent: any = null;
  let subMenus: any[] = [];
  let selectMenuId = 0;
  let selectMenuName = '';

  menuData.forEach((menu: any, index: number) => {
    if (menu.children) {
      menu.children.forEach((item: any) => {
        if (item.url && item.url !== '') {
          if (isSelected(item.url, pathUrl)) {
            console.log('level 1 menu', item.id);
            hasPower = isAuth(item.id);
            selectIndex = index;
            isInMenu = true;
            selectMenuId = item.id;
            if (item.hideSidder) {
              // sidder隐藏
              renderSilder = false;
            }
            return;
          }
        }
        if (!hasPower && item.children) {
          item.children.forEach((child: any) => {
            if (isSelected(child.url, pathUrl)) {
              console.log('level 2 menu', child.id);
              hasPower = isAuth(child.id);
              selectIndex = index;
              selectMenuId = child.id;
              isInMenu = true;
              return;
            }
            if (!hasPower && child.children) {
              child.children.forEach((sub: any) => {
                if (isSelected(sub.url, pathUrl)) {
                  console.log('level 3 menu', sub.id);
                  hasPower = isAuth(sub.id);
                  selectIndex = index;
                  isInMenu = true;

                  selectMenuId = sub.id;
                  subMenus = child.children;
                  selectMenuName = child.noShowName ? '' : child.name;
                  return;
                }
              });
            }
          });
        }
      });
    } else if (menu.url && menu.url !== '') {
      if (isSelected(menu.url, pathUrl)) {
        hasPower = isAuth(menu.id);
        selectIndex = index;
        isInMenu = true;
        renderSilder = false;
        selectMenuId = menu.id;
        return;
      }
    }
  });

  const onFold = (flag: boolean) => {
    setFeature(FeatureTags.MUED_IS_FOLD, flag);
    setCollapsed(flag);
  };

  if (!isInMenu) {
    history.push('/404');
    return null;
  }

  if (nickName && selectIndex === -1) {
    return <Empty description="您没有该页面的访问权限" />;
  }

  // 如果只有设置且没有其它左侧菜单，则隐藏左侧菜单
  if (menuData.length === 1 && menuData[0].id === SETTING_MENU_ID) {
    renderSilder = false;
  }

  // 选中的顶部导航
  const curNavMenu = menuData[selectIndex];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { CustomSidderMenu } = curNavMenu;

  const renderContent = useMemo(() => {
    if (subMenus.length > 0) {
      return (<ErrorBoundary>
        <SubMenu
          menuData={subMenus}
          history={history}
          selectMenuId={selectMenuId}
          selectMenuName={selectMenuName}>
          <div className={styles['sub-content-wrapper']}>{props.children}</div>
        </SubMenu>
      </ErrorBoundary>);
    }
    return (<ErrorBoundary>
      <div className={styles['content-wrapper']}>{props.children}</div>
    </ErrorBoundary>);
  }, [subMenus, props.children, history, selectMenuId, selectMenuName]);


  // 昵称没有则代表接口还未返回数据
  return (
    <TopBottom loading={!nickName} selectIndex={selectIndex} history={history}>
      <Layout style={{ background: 'none' }}>

        {/* 暂时只有ai体验室用到 */}
        {
          (renderSilder && CustomSidderMenu)
            ? (
              <Sider width={212} style={{ margin: '0 24px' }}>
                <CustomSidderMenu
                  path={pathUrl}
                  history={history}
                  selectIndex={selectIndex}
                  curNavMenu={curNavMenu}
                />
              </Sider>
            )
            : null
        }

        {
          (renderSilder && !CustomSidderMenu)
            ? (<Sider
              width = {isCollapsed ? 60 : 204}
              style={{
                background: 'none',
              }}>
              <Menu
                key={'menu'}
                path={pathUrl}
                history={history}
                selectIndex={selectIndex}
                onFold={onFold}
                isCollapsed={isCollapsed} />
            </Sider>
            )
            : null
        }

        {/* 不展示sidder的时候。左边补margin */}
        <Content style={{
          ...contentStyle,
          marginLeft: renderSilder ? 0 : '24px',
        }}>
          {renderContent}
        </Content>
      </Layout>
      <Footer style={footerStyle}>
          Copyright @ 2019-2023 Tencent. All Rights Reserved
      </Footer>
    </TopBottom>
  );
};

export default LayoutModule;
