// 私域 API地址
import { HOST } from '../config';

const ptApi = {
  // 私域查询推送计划列表
  campaignList: `${HOST}/api/marketing/campaign/list`,

  // 私域保存推送计划
  campaignSave: `${HOST}/api/marketing/campaign/save`,

  // 私域启动推送计划
  campaignStart: `${HOST}/api/marketing/campaign/start`,

  // 私域停用推送计划
  campaignStop: `${HOST}/api/marketing/campaign/stop`,

  // 私域删除推送计划
  campaignDelete: `${HOST}/api/marketing/campaign/delete`,

  // 私域检查推送计划名称重复
  campaignCheckName: `${HOST}/api/marketing/campaign/checkName`,

  // 检查推送计划粉丝来源是否冲突
  campaignCheckFansSource: `${HOST}/api/marketing/campaign/checkFansSource`,

  // 检查计划列表配置提醒是否绑定小程序、公众号等
  campaignConfigRemind: `${HOST}/api/marketing/campaign/configRemind`,

  // 查询计划详情
  campaignDetail: `${HOST}/api/marketing/campaign/detail`,

  // 停用推送计划AB实验
  campaignStopAB: `${HOST}/api/marketing/campaign/stopAB`,

  // 私域查询推送内容列表
  contentList: `${HOST}/api/marketing/content/list`,

  // 私域保存推送内容
  contentSave: `${HOST}/api/marketing/content/save`,

  // 查询推送内容详情
  contentDetail: `${HOST}/api/marketing/content/detail`,

  // 删除推送内容
  contentDelete: `${HOST}/api/marketing/content/delete`,

  // 检查推送内容名称是否重复
  contentCheckName: `${HOST}/api/marketing/content/checkName`,

  // 非广告来源粉丝列表
  getFansChannelLabels: `${HOST}/api/marketing/campaign/getFansChannelLabels`,

  // 企微第三方应用-授权
  queryWeComAuthState: `${HOST}/api/marketing/wecom/authority`,

  // 企微第三方应用-授权列表
  queryWecomAuthoritylist: `${HOST}/api/marketing/wecom/authoritylist`,

  // 企微第三方应用-订单保存接口
  wecomOrderSave: `${HOST}/api/marketing/wecom/order/save`,

  // 企微第三方应用-订单查询接口
  wecomOrderList: `${HOST}/api/marketing/wecom/order/list`,

  // 企微第三方应用-朋友圈接口
  wecomMomentList: `${HOST}/api/marketing/wecom/moment/list`,

  // 企微第三方应用-用户洞察接口
  wecomCustomerDetail: `${HOST}/api/marketing/wecom/customer/detail`,

  // 获取会话存档配置
  conversationConfigGet: `${HOST}/api/marketing/wecom/conversation/config/get`,

  // 保存会话存档配置
  conversationConfigSave: `${HOST}/api/marketing/wecom/conversation/config/save`,

  // 校验会话存档配置
  conversationConfigCheck: `${HOST}/api/marketing/wecom/conversation/config/check`,

  // 私域 - 查询店铺是否为私域店铺以及其他信息
  externalShopGet: `${HOST}/api/marketing/external/shop/get`,

  // 保存对外收账配置
  billConfigSave: `${HOST}/api/marketing/wecom/bill/config/save`,

  // 保存对外收账:校验配置
  billConfigCheck: `${HOST}/api/marketing/wecom/bill/config/check`,

  // 查询对外收账配置
  billConfigQuery: `${HOST}/api/marketing/wecom/bill/config/query`,

  // 查询鉴权状态
  getWecomAppAuth: `${HOST}/api/marketing/data/analysis/cterminal/guide/assistant/getAuthInfo`,

  getWecomThirdAppSdkInfo: `${HOST}/api/marketing/data/analysis/login/third/jsSdkSignature`,
};

export { ptApi };
