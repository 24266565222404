
const tplApi = {
  // 获取话术模板查询条件
  tplCondition: `${ZhbHost}/api/marketing/data/analysis/tools/template/getConditionList`,

  // 获取话术模板列表
  tplList: `${ZhbHost}/api/marketing/data/analysis/tools/template/search`,

  // 话术模板编辑
  tplEdit: `${ZhbHost}/api/marketing/data/analysis/tools/template/edit`,
};

export { tplApi };
