
const aiApi = {
  // 查询商品
  aiGoodsList: `${ZhbHost}/api/marketing/copywriting/goods/list`,

  // 更新商品
  aiGoodsUpdate: `${ZhbHost}/api/marketing/copywriting/goods/save`,

  // 查询导购列表
  aiProfileList: `${ZhbHost}/api/marketing/copywriting/guider/list`,

  // 更新导购人设
  aiProfileUpdate: `${ZhbHost}/api/marketing/copywriting/guider/save`,

  // 查询收藏列表
  aiFavoriteList: `${ZhbHost}/api/marketing/copywriting/store/list`,

  // 更新收藏
  aiFavoriteUpdate: `${ZhbHost}/api/marketing/copywriting/store/save`,

  // 导出
  aiFavoriteExport: `${ZhbHost}/api/marketing/copywriting/store/export`,

  // 获取答案
  aiGetAnswer: `${ZhbHost}/api/marketing/copywriting/generation/ask`,

  // 获取初始化数据
  aiGetInitData: `${ZhbHost}/api/marketing/copywriting/generation/init`,

  // 反馈
  aiFeedback: `${ZhbHost}/api/marketing/copywriting/generation/feedback`,

  // 获取白名单
  getWhiteList: `${ZhbHost}/api/marketing/whitelist/getShopWhiteList`,
};

export { aiApi };
