import { Request } from '@common/requestHelper';
import { authApi } from 'maker/config';


// 获取用户信息
export const getUserInfo = async (token: string) => {
  const res = await Request.get(authApi.getUserInfo, {}, {
    showError: false,
    headers: {
      token,
    },
  });
  return res.getResponse();
};
