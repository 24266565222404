/**
 * 上下结构
 */
import React, { CSSProperties } from 'react';
import { Layout } from '@tencent/adp-combine-ui';
import ErrorBoundary from './errorBoundary';
import ZubHeader from './header';
import styles from './header.less?local';
import { LoadingAnimate } from '@tencent/adp-icons-react';
const { Header } = Layout;

const layoutStyle: CSSProperties = {
  height: 'calc(100vh)',
  background: 'none',
};

const headerFooterStyle: CSSProperties = {
  textAlign: 'left',
  padding: 0,
  position: 'relative',
  height: 88,
  lineHeight: '30px',
  background: 'none',
};

const mainStyle: CSSProperties = {
  overflow: 'hidden',
  background: 'none',
};

const TopBottom = (props: any) => {
  const { path, loading = false } = props;
  return (
    <div className={styles.body_bg}>
      <Layout style={layoutStyle}>
        <Header style={headerFooterStyle}>
          {loading
            ? <LoadingAnimate className={styles.headerLoading} size={24} fill="#296BEF" />
            : <ZubHeader path={path} history={props.history} selectIndex={props.selectIndex} />}
        </Header>
        <Layout style={mainStyle}>
          <ErrorBoundary>
            {props.children}
          </ErrorBoundary>
        </Layout>
      </Layout>
    </div>
  );
};

export default TopBottom;
