import React from 'react';


const WithSubApp = (OriginalComponent: any) => {
  const NewComponent = (props: any) => <OriginalComponent
    isSub={true}
    {...props}
  />;
  return NewComponent;
};

export default WithSubApp;
