const HOST = ZhbHost;

export const aiGuideApi = {
  // 获取客户信息
  aigcPlainUserGet: `${HOST}/api/marketing/aigc//plain/user/get`,

  // 获取accessToken
  aigcCAccessToken: `${HOST}/api/marketing/aigc/c/accessToken`,

  // 获取B端accessToken
  aigcBAccessToken: `${HOST}/api/marketing/aigc/b/accessToken`,
};
