import React, { CSSProperties } from 'react';
import { Spin } from '@tencent/adp-combine-ui';
import { Container } from 'maker/components/zhb/container';
import Empty from 'maker/components/Empty';

import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import { Link } from 'react-router-dom';

const style: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const WithCheckCorp = (OriginalComponent: any) => {
  const NewComponent = (props: any) => {
    const { corpInfo } = useGlobalStore();

    if (corpInfo.loading) {
      return <div style={style}><Spin /></div>;
    }

    if (corpInfo.id === '') { // 未绑定第三方企微
      return <Container title=""  hasBG><Empty
        emptyTips={
          <>
            请前往 <Link to="/wecomAuth" target="_blank">【设置-广告应用授权】</Link> 进行授权操作
          </>
        }
      /></Container>;
    }

    return (
      <OriginalComponent
        corpId={corpInfo.id}
        corpName={corpInfo.name}
        isBind={corpInfo.isConversation}
        {...props}
      />
    );
  };

  return NewComponent;
};

export default WithCheckCorp;
