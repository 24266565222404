/**
 * @file actionReport.ts
 * @author markJia(markjia@tencent.com)
 */

import { createZhbActionReport } from '@tencent/zhb-biz-react-ui';
import { request } from '../services/zhb/request';

export const zhbAdminActionReporterIns = createZhbActionReport({
  url: '/api/marketing/data/analysis/event/report',
  request,
});
