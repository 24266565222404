import React from 'react';
const Empty = (props: any) => (
  <div style={{ margin: '100px auto', textAlign: 'center' }}>
    <div>
      <svg
        width="124"
        height="125"
        viewBox="0 0 124 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3070_55511)">
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M124.782 111.709C124.791 111.508 124.796 111.307 124.796 111.106C124.796 96.113 99.1374 83.959 67.4861 83.959C35.8347 83.959 10.1763 96.113 10.1763 111.106C10.1763 111.307 10.1809 111.508 10.1901 111.709H124.782Z"
            fill="url(#paint0_linear_3070_55511)"
          />
          <path
            d="M46.619 45.6024C46.7551 44.6125 47.6011 43.875 48.6003 43.875H60.7583C61.3254 43.875 61.8659 44.1158 62.2452 44.5374L65.4214 48.068C65.8007 48.4896 66.3412 48.7304 66.9083 48.7304H103.703C104.926 48.7304 105.863 49.8188 105.681 51.0285L98.7772 96.8222C98.5559 98.2898 97.2948 99.375 95.8107 99.375H41.5156C40.3027 99.375 39.369 98.304 39.5343 97.1024L46.619 45.6024Z"
            fill="url(#paint1_linear_3070_55511)"
          />
          <path
            d="M31.4299 63.7439C31.1788 62.5049 32.1259 61.3467 33.3901 61.3467H87.8555C89.7584 61.3467 91.3979 62.6872 91.7758 64.5522L98.3472 96.9772C98.5983 98.2162 97.6512 99.3745 96.387 99.3745H40.2862C39.3348 99.3745 38.515 98.7042 38.3261 97.7717L31.4299 63.7439Z"
            fill="#EAF2FE"
          />
          <path
            d="M31.9336 63.6419C31.747 62.7212 32.4507 61.8606 33.3901 61.8606H87.8555C89.5139 61.8606 90.9428 63.0289 91.2722 64.6543L97.8435 97.0793C98.0301 97.9999 97.3264 98.8606 96.387 98.8606H40.2862C39.5792 98.8606 38.9701 98.3625 38.8297 97.6696L31.9336 63.6419Z"
            stroke="url(#paint2_linear_3070_55511)"
            strokeOpacity="0.3"
            stroke-width="1.02778"
          />
          <rect
            x="4.27734"
            y="28.459"
            width="34.9444"
            height="21.5833"
            rx="4.11111"
            fill="#EAF2FE"
          />
          <rect
            x="17.6382"
            y="34.626"
            width="15.4167"
            height="3.08333"
            rx="1.54167"
            fill="white"
          />
          <rect
            x="10.4441"
            y="40.792"
            width="22.6111"
            height="3.08333"
            rx="1.54167"
            fill="white"
          />
          <rect
            x="10.4441"
            y="34.626"
            width="4.11111"
            height="3.08333"
            rx="1.54167"
            fill="white"
          />
          <path d="M30.9993 54.1543L34.5596 49.5293H27.439L30.9993 54.1543Z" fill="#EAF2FE" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_3070_55511"
            x1="68.5138"
            y1="83.959"
            x2="68.5138"
            y2="111.709"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3EEFF" />
            <stop offset="1" stopColor="#EAF2FE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3070_55511"
            x1="88.9578"
            y1="98.985"
            x2="88.5612"
            y2="45.3919"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#93B6FF" />
            <stop offset="0.338983" stopColor="#A7C3FF" />
            <stop offset="0.834746" stopColor="#C2D6FF" />
            <stop offset="1" stopColor="#CADBFF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3070_55511"
            x1="87.0135"
            y1="61.3467"
            x2="81.3608"
            y2="80.8745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3070_55511">
            <rect
              width="123.333"
              height="123.333"
              fill="white"
              transform="translate(0.166748 0.708008)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    {props.title && <div style={{ color: '#888', fontSize: 12 }}>{props.title}</div>}
    {props.emptyTips && <div style={{ color: '#888', fontSize: 12 }}>{props.emptyTips}</div>}
  </div>
);
export default Empty;
