import React from 'react';
import { Button } from '@tencent/adp-combine-ui';
import { Upload } from '@tencent/adp-icons-react';
import styles from './button.less?local';

interface ButtonProps {
  children: any;
  type?: 'add' | 'export';
  onClick?: any;
  disabled?: boolean;
  className?: string;
}

const ButtonCompotent = (props: ButtonProps) => {
  if (props.type === 'add') {
    return (
      <>
        <Button disabled={props.disabled ? props.disabled : false} type="primary"
          className={`${styles.button} ${props.className ? props.className : ''}`}
          onClick={props.onClick}>
          <span className={styles['icon-plus']}>+</span>
          {props.children}</Button>
      </>
    );
  } if (props.type === 'export') {
    return (
      <>
        <Button type="primary"
          className={`${styles.button} ${props.className ? props.className : ''}`}
          onClick={props.onClick}>
          <span className={styles['icon-plus']}><Upload fill="#fff" size={14} /></span>
          {props.children}</Button>
      </>
    );
  }
  return (<>
    <Button type="primary"
      className={`${styles.button} ${props.className ? props.className : ''}`}
      onClick={props.onClick}>
      {props.children}</Button>
  </>);
};

export default ButtonCompotent;
