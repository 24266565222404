/**
 * @file aegis.ts
 * @author markJia(markjia@tencent.com)
 */

import { initAegis, reportAegisCustomEvent, AegisEnv, reportAegisErrorLog } from '@tencent/zhb-biz-util';

let env: AegisEnv = AegisEnv.TEST;

if (process.env.prod === 'production') {
  env = AegisEnv.PROD;
} else if (process.env.prod === 'green') {
  env = AegisEnv.PRE;
}

console.log('===== env: ', process.env.prod);

export const adminAegisIns = initAegis({
  // 管理端id
  id: 'ZIvxTGYWysvqFauEgk',
  env,
  reportApiSpeed: true,
  reportAssetSpeed: true,
  spa: true,
  expectedApiRespCode: [401],
  ignoreDataUrls: [
    // 企微sdk上报的接口，返回值不通用，监控jssdk初始化就好
    /^https:\/\/open.work.weixin.qq.com\/wwopen\/openData\/agentConfig/,
    // get请求
    /^https:\/\/test.bricks.e.qq.com\/linkAnalysis\/funnel/,
    /qv-scrm\/customer-manage\/customer\/list/,
  ],
  // 参数 + 返回值
  api: {
    apiDetail: true,
    reportRequest: true,
  },
});

export const reportAdminAegisEvent = reportAegisCustomEvent;
export const reportAdminAegisError = reportAegisErrorLog;


