
const reportApi = {
  // 数据看板-更新商家自定义列配置
  statUpdateFieldConfig: `${ZhbHost}/api/marketing/statistics/adv/updateCustomFieldConfig`,

  // 数据看板-获取商家自定义列配置
  statGetFieldConfig: `${ZhbHost}/api/marketing/statistics/adv/getCustomFieldConfig`,

  // 数据看板-渠道效果获取下拉列表
  statChannelOpsList: `${ZhbHost}/api/marketing/statistics/adv/getConditionValueList`,

  // 数据看板-渠道效果数据列表
  statChannelDataList: `${ZhbHost}/api/marketing/statistics/adv/query`,

  // 数据看板-渠道效果数据导出
  statChannelDataExport: `${ZhbHost}/api/marketing/statistics/adv/export`,

  // 数据看板 - overview下拉列表
  statOverflowOpsList: `${ZhbHost}/api/marketing/statistics/overview/getConditionValueList`,

  // 数据看板 - 查询数据概览统计数据
  statOverflowData: `${ZhbHost}/api/marketing/statistics/overview/query`,
};

export { reportApi };
