import React from 'react';
import type { PropsWithChildren } from 'react';
import { Empty } from '@tencent/adp-combine-ui';

type Props = PropsWithChildren<any>;
interface State {
  error: boolean | Error;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      // 你可以自定义降级后的 UI 并渲染
      return <Empty description="访问的页面发生系统错误" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;