import Request from '@common/requestHelper';
import { authApi } from 'maker/config';

// 登录
export async function login(params: any) {
  const res = await Request.post(
    authApi.login,
    params,
    {
      showError: false,
      headers: {
        'content-type': 'application/json',
      },
    },
  );
  if (!res.isSuccess()) {
    throw res.getResponse();
  }
  return res.getResponse();
}

// ssoLogin
export async function ssoLogin(params: any = {}) {
  const res = await Request.post(authApi.ssoLogin, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });
  return res.getResponse();
}

// 获取手机号验证码
export async function getPhoneCode(params: any) {
  const res = await Request.post(authApi.sendPhoneAuth, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });
  if (!res.isSuccess()) {
    throw res.getResponse();
  }
  return res.getResponse();
}


// 注册
export async function register(params: any) {
  const res = await Request.post(authApi.register, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });

  if (!res.isSuccess()) {
    throw res.getResponse();
  }

  return res.getResponse();
}

// 校验验证码
export async function checkCode(params: any) {
  const res = await Request.post(authApi.checkCode, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });

  if (!res.isSuccess()) {
    throw res.getResponse();
  }

  return res.getResponse();
}

// 校验手机号码
export async function checkPhone(params: any) {
  const res = await Request.post(authApi.checkPhone, JSON.stringify(params), {
    showError: false,
    headers: {
      'content-type': 'application/json',
    },
  });

  if (!res.isSuccess()) {
    throw res.getResponse();
  }

  return res.getResponse();
}
