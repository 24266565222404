/**
 * @file hooks.ts
 * @desc 获取corp信息
 * @author markJia(markjia@tencent.com)
 */

import { reqIns } from '../../common/requestHelper';
import { queryWecomAuthoritylist } from '../services/privateTraffic/wecomSidebar';
import { instance } from '../services/zhb/request';
import { AuthoritylistResponse } from '../types/models/privateTraffic/wecomAuthorityList';
import { CorpContextInfo } from './context';
import { getCustomCookie, setCustomCookie } from 'maker/utils/session';

export const ZHB_DEFAULT_CORP_ID_KET = 'zhb_default_corp_id';

const getNoAuthCorpInfo = (): CorpContextInfo => ({
  loading: false,
  id: '',
  name: '',
  isConversation: false,
  thirdCorpList: [],
});

// 更新授权信息
const getDefaultCorpInfo = (defaultCorpId: string | null, thirdList: AuthoritylistResponse['data']['list']): CorpContextInfo => {
  const currentCorpInfo = thirdList.find(corp => corp.corpId === defaultCorpId) || thirdList[0];

  if (currentCorpInfo) {
    const { corpId, corpName, isAuthConversation } = currentCorpInfo;
    return {
      loading: false,
      id: corpId,
      name: corpName,
      isConversation: isAuthConversation === 1,
      thirdCorpList: thirdList,
    };
  }

  // 切换到空账户时，避免出现旧信息。
  return getNoAuthCorpInfo();
};

export const updateCorpId = (corpId: string): void => {
  // corpId 不是默认id才更新
  if (corpId) {
    // 更新cookie: 1. 记录当前登录主体 2. 给子应用传参
    setCustomCookie(ZHB_DEFAULT_CORP_ID_KET, `${corpId}`);

    // 新request 带类型
    instance.defaults.headers['corp-id'] = corpId;
    instance.defaults.headers.corpId = corpId;

    // scrm历史request
    reqIns.defaults.headers['corp-id'] = corpId;
    reqIns.defaults.headers.corpId = corpId;
  }
};

export const getThirdCorpInfo = async (): Promise<CorpContextInfo> => {
  const res = await queryWecomAuthoritylist({ pageNum: 1, pageSize: 1000 });
  if (!res.isSuccess()) {
    return {
      ...getNoAuthCorpInfo(),
      loading: true,
    };
  }

  const { list } = res.getData<AuthoritylistResponse['data']>();
  const validThirdList = list.filter(corp => corp.valid);
  // 获取上次登录的corpId，如果有则自动选择
  const defaultCorpId = getCustomCookie(ZHB_DEFAULT_CORP_ID_KET);
  const corpInfo = getDefaultCorpInfo(defaultCorpId, validThirdList);
  updateCorpId(corpInfo.id);
  return corpInfo;
};


