
const sandTableApi = {
  sandtableTemplateList: `${ZhbHost}/api/marketing/data/analysis/sandtable/template/list`,

  templateWordcloudList: `${ZhbHost}/api/marketing/data/analysis/sandtable/template/wordcloud/list`,

  templateReplyRist: `${ZhbHost}/api/marketing/data/analysis/sandtable/template/reply/list`,

  userEfficiencyList: `${ZhbHost}/api/marketing/data/analysis/sandtable/user/efficiency/list`,

  userWordcloudList: `${ZhbHost}/api/marketing/data/analysis/sandtable/user/wordcloud/list`,

  // 客户概览
  overview: `${ZhbHost}/api/marketing/data/analysis/sandtable/customer/overview`,

  // 获取是否过期
  checkIsExpire: `${ZhbHost}/api/marketing/wecom/conversation/config/getAuthCompleteTime`,

  // 导购人效汇总
  effectOverview: `${ZhbHost}/api/marketing/data/analysis/sandtable/user/efficiency/overview`,

  // 获取企微签名
  getJsSdkSignature: `${ZhbHost}/api/marketing/wecom/externalContact/jsSdkSignature`,
};

export { sandTableApi };
