/*
 * @Author: hillliu
 */
import { useContext } from 'react';

import Context, { GlobalContextValue } from 'maker/stores/context';

/** 获取全局配置 */
export function useGlobalStore() {
  const ctxValue = useContext(Context);
  if (!ctxValue) {
    throw new Error('useGlobalStore must under CorpContext');
  }

  return ctxValue.state;
}

/** 获取全局provider */
export function useGlobalContext(): GlobalContextValue {
  const ctxValue = useContext(Context);
  if (!ctxValue) {
    throw new Error('useGlobalStore must under CorpContext');
  }

  return ctxValue;
}
