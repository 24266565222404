/**
 *  落地页的范围日期组件定制快捷选择功能
 */
import React, { useEffect, useState, useRef } from 'react';
import { DatePicker, Button, message } from '@tencent/adp-combine-ui';
import moment, { Moment } from 'moment';
import styles from './datePicker.less?local';

const { RangePicker } = DatePicker;
interface DatePickerProps {
  onChange?: any;
  disableNull?: boolean;
  width?: number;
  format?: string;
  defaultValue?: [Moment, Moment];
}

const DatePickerCompotent = (props: DatePickerProps) => {
  let defaultVal: [Moment | null, Moment | null] | null = props.defaultValue || null;
  if (props.disableNull && !defaultVal) {
    defaultVal = [moment(), moment()];
  }
  const [range, setRange] = useState<[Moment | null, Moment | null] | null>(defaultVal);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const clockRef = useRef(null);
  const rangeRef = useRef(defaultVal);

  useEffect(() => {
    const handleMouseUp = () => {
      // 延迟关闭
      clockRef.current = window.setTimeout(() => {
        setOpenStatus(false);
        setRange(rangeRef.current);
      }, 100);
    };
    window.addEventListener('mouseup', handleMouseUp, false);

    // 卸载时清除事件
    return () => {
      window.removeEventListener('mouseup', handleMouseUp, false);
    };
  }, []);

  // 快捷选择
  const setQuickRange = (type: number) => {
    if (type === 1) {
      setRange([moment(), moment()]);
    } else if (type === 2) {
      setRange([moment().subtract(1, 'days'), moment().subtract(1, 'days')]);
    } else if (type === 3) {
      setRange([moment().subtract(6, 'days'), moment()]);
    } else if (type === 4) {
      setRange([moment().subtract(29, 'days'), moment()]);
    } else {
      setRange(null);
    }
  };

  // 点击确定才回调，否则还原日期值
  const onConfirm = (e: any) => {
    if (range && range?.length > 1) {
      if (props.disableNull) {
        if (!range[0]) {
          message.info('请选择开始日期');
          return;
        }
        if (!range[1]) {
          message.info('请选择结束日期');
          return;
        }
      }
      const startDate = range[0] ? range[0].format(props.format || 'YYYY-MM-DD') : null;
      const endDate = range[1] ? range[1].format(props.format || 'YYYY-MM-DD') : null;
      rangeRef.current = range;
      if (props.onChange) {
        props.onChange(startDate, endDate);
      }
    } else {
      rangeRef.current = range;
      if (props.onChange) {
        props.onChange(null, null);
      }
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setOpenStatus(false);
  };

  // 取消
  const onCancel = () => {
    setRange(rangeRef.current);
    setOpenStatus(false);
  };

  return (
    <span onClick={(e) => {
      if (clockRef.current) {
        window.clearTimeout(clockRef.current);
      }
      setOpenStatus(true);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }}>
      <RangePicker
        allowClear={!props.disableNull}
        open={openStatus}
        value={range}
        style={{ width: props.width ? props.width :  260 }}
        onCalendarChange={(dates: any) => {
          setRange(dates);
        }}
        renderExtraFooter={() => (<div className={styles['footer-wrapper']}>
          <div className={styles['quick-div']}>
            <span onClick={() => {
              setQuickRange(1);
            }}>今天</span>
            <span onClick={() => {
              setQuickRange(2);
            }}>昨天</span>
            <span onClick={() => {
              setQuickRange(3);
            }}>最近7天</span>
            <span onClick={() => {
              setQuickRange(4);
            }}>最近30天</span>
            {!props.disableNull && (<span onClick={() => {
              setQuickRange(0);
            }}>全部</span>)}
          </div>
          <div className={styles['btns-div']}>
            <Button size="sm" style={{ marginRight: 8 }} onClick={(e) => {
              onCancel();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}>取消</Button>
            <Button type="primary" size="sm" onClick={(e) => {
              onConfirm(e);
            }}>确定</Button>
          </div>
        </div>)}
      />
    </span>);
};

export default DatePickerCompotent;
