/* eslint-disable max-len */
import React from 'react';
import { ArrowDownOne, ArrowUpOne } from '@tencent/adp-icons-react';

import './icon.less';

interface IconProps {
  color: string;
  size?: number; // px
}

const SvgWechat = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8" cy="8.7002" r="7.5" stroke={props.color} />
  <path d="M2.39941 8.2002C2.39941 6.54334 3.74256 5.2002 5.39941 5.2002H10.077C12.0224 5.2002 13.5994 6.77723 13.5994 8.7226V8.7226C13.5994 10.3971 12.4205 11.8402 10.7797 12.1742L7.99941 12.7402V11.2002H5.39941C3.74256 11.2002 2.39941 9.85705 2.39941 8.2002V8.2002Z" stroke={props.color} />
  <mask id="path-3-inside-1_1398_744" fill="white">
    <ellipse cx="10.338" cy="8.31284" rx="1" ry="1.2" transform="rotate(-7 10.338 8.31284)" />
  </mask>
  <path d="M10.338 8.31284C10.3514 8.42164 10.3227 8.49612 10.3027 8.52708C10.2935 8.54139 10.2905 8.54016 10.3012 8.53295C10.3135 8.52466 10.3353 8.51467 10.3624 8.51135L10.6061 10.4964C11.8642 10.342 12.4494 9.09794 12.3231 8.0691L10.338 8.31284ZM10.3624 8.51135C10.3895 8.50802 10.413 8.51245 10.427 8.51751C10.4391 8.52191 10.4365 8.52384 10.4241 8.51218C10.3972 8.48697 10.3514 8.42164 10.338 8.31284L8.35292 8.55658C8.47924 9.58542 9.34803 10.6509 10.6061 10.4964L10.3624 8.51135ZM10.338 8.31284C10.3246 8.20403 10.3533 8.12956 10.3733 8.0986C10.3825 8.08429 10.3855 8.08552 10.3748 8.09273C10.3625 8.10102 10.3407 8.11101 10.3136 8.11433L10.0699 6.12924C8.8118 6.28371 8.22659 7.52774 8.35292 8.55658L10.338 8.31284ZM10.3136 8.11433C10.2866 8.11765 10.263 8.11323 10.2491 8.10817C10.2369 8.10377 10.2395 8.10184 10.252 8.1135C10.2788 8.13871 10.3246 8.20403 10.338 8.31284L12.3231 8.0691C12.1968 7.04026 11.328 5.97476 10.0699 6.12924L10.3136 8.11433Z" fill={props.color} mask="url(#path-3-inside-1_1398_744)" />
  <mask id="path-5-inside-2_1398_744" fill="white">
    <ellipse cx="1" cy="1.2" rx="1" ry="1.2" transform="matrix(0.992546 0.121869 0.121869 -0.992546 4.39941 9.38232)" />
  </mask>
  <path d="M5.5382 8.31314C5.55156 8.20433 5.52289 8.12986 5.50294 8.0989C5.49371 8.08459 5.49068 8.08582 5.5014 8.09303C5.51373 8.10132 5.53551 8.11131 5.56258 8.11463L5.80632 6.12954C7.06441 6.28401 7.64962 7.52804 7.5233 8.55688L5.5382 8.31314ZM5.56258 8.11463C5.58965 8.11795 5.6132 8.11353 5.62716 8.10847C5.63931 8.10407 5.63667 8.10214 5.62425 8.1138C5.5974 8.13901 5.55156 8.20433 5.5382 8.31314L3.55311 8.0694C3.67944 7.04056 4.54822 5.97506 5.80632 6.12954L5.56258 8.11463ZM5.5382 8.31314C5.52484 8.42194 5.55352 8.49642 5.57347 8.52738C5.5827 8.54169 5.58573 8.54046 5.575 8.53325C5.56268 8.52496 5.5409 8.51497 5.51383 8.51165L5.27009 10.4967C4.012 10.3423 3.42679 9.09824 3.55311 8.0694L5.5382 8.31314ZM5.51383 8.51165C5.48676 8.50832 5.46321 8.51275 5.44925 8.51781C5.4371 8.52221 5.43974 8.52414 5.45215 8.51248C5.47901 8.48727 5.52484 8.42194 5.5382 8.31314L7.5233 8.55688C7.39697 9.58572 6.52818 10.6512 5.27009 10.4967L5.51383 8.51165Z" fill={props.color} mask="url(#path-5-inside-2_1398_744)" />
</svg>
  ;

const SvgGrant = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.97189 14.936H7.96877C7.40627 14.9329 5.6922 13.7876 4.67814 13.0189C2.45939 11.336 1.99689 10.4345 2.00002 9.97354L2.03283 3.67354C2.03439 3.37667 2.2047 3.10948 2.46564 2.99385L2.48595 2.98604L7.77814 1.05792C7.95002 0.986041 8.14377 0.986041 8.31564 1.06104L13.6078 3.04854C13.8672 3.16729 14.0344 3.43604 14.0328 3.73292L14 10.0329C13.9938 11.0345 12.5172 12.2954 11.2797 13.1751C10.061 14.0407 8.4922 14.936 7.97189 14.936ZM2.71564 3.5751C2.68595 3.59229 2.66564 3.63292 2.66564 3.67667L2.63283 9.97667C2.63127 10.3189 3.37033 11.2267 5.02814 12.4876C6.44533 13.5657 7.72033 14.2814 7.97658 14.3017C8.65002 14.2751 13.3578 11.3345 13.3656 10.0282L13.3985 3.72823C13.3985 3.68448 13.3781 3.64385 13.35 3.62667L8.06252 1.64073C8.05002 1.63448 8.03595 1.63448 8.02189 1.64073L8.00158 1.64854L2.71564 3.5751Z" fill={props.color} stroke={props.color} strokeWidth="0.5" />
  <path d="M7.23917 9.97492C7.12198 9.97492 7.0048 9.93117 6.91417 9.84367L4.94542 7.94054C4.82042 7.81867 4.81573 7.61867 4.93761 7.4921C5.05948 7.3671 5.25948 7.36242 5.38605 7.48429L7.24073 9.27804L10.647 6.04992C10.7735 5.9296 10.9751 5.93429 11.0939 6.06242C11.2142 6.18898 11.2095 6.39054 11.0814 6.50929L7.56105 9.84679C7.47042 9.93273 7.3548 9.97492 7.23917 9.97492Z" fill={props.color} stroke={props.color} strokeWidth="0.5" />
</svg>
  ;

const SvgReportDashbord = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.999 3.52729V1.58285C14.999 1.5459 14.9951 1.50896 14.9873 1.47201C14.9854 1.4584 14.9834 1.44479 14.9776 1.43118C14.9679 1.3884 14.9523 1.34757 14.9309 1.31062C14.9232 1.29507 14.9134 1.27757 14.9018 1.26201C14.8921 1.24451 14.8804 1.22896 14.8668 1.21534C14.8434 1.18423 14.8143 1.15507 14.7832 1.13173C14.7696 1.11812 14.754 1.10646 14.7365 1.09673C14.7209 1.08507 14.7034 1.07535 14.6879 1.06757C14.6509 1.04618 14.6101 1.03062 14.5673 1.0209C14.5537 1.01507 14.5401 1.01312 14.5265 1.01118C14.4896 1.0034 14.4526 0.999512 14.4157 0.999512H12.4712C12.1484 0.999512 11.8879 1.26007 11.8879 1.58285C11.8879 1.90562 12.1484 2.16618 12.4712 2.16618H13.0079L9.36011 5.81396L6.27233 2.72618C6.16296 2.61694 6.01469 2.55558 5.86011 2.55558C5.70552 2.55558 5.55726 2.61694 5.44789 2.72618L1.17011 7.00396C1.06087 7.11333 0.999512 7.26159 0.999512 7.41618C0.999512 7.57076 1.06087 7.71903 1.17011 7.8284C1.28289 7.94312 1.43261 7.99951 1.58233 7.99951C1.73205 7.99951 1.88178 7.94312 1.99455 7.8284L5.86011 3.96284L8.94789 7.05062C9.06067 7.16534 9.21039 7.22173 9.36011 7.22173C9.50983 7.22173 9.65955 7.16534 9.77233 7.05062L13.8323 2.99062V3.52729C13.8323 3.85007 14.0929 4.11062 14.4157 4.11062C14.7384 4.11062 14.999 3.85007 14.999 3.52729Z" fill={props.color} />
  <path d="M2.33652 14.8287C2.44592 14.9381 2.59429 14.9995 2.749 14.9995C2.90371 14.9995 3.05208 14.9381 3.16148 14.8287C3.27087 14.7193 3.33233 14.5709 3.33233 14.4162V10.1384C3.33233 9.98369 3.27087 9.83532 3.16148 9.72592C3.05208 9.61653 2.90371 9.55507 2.749 9.55507C2.59429 9.55507 2.44592 9.61653 2.33652 9.72592C2.22712 9.83532 2.16567 9.98369 2.16567 10.1384V14.4162C2.16567 14.5709 2.22712 14.7193 2.33652 14.8287Z" fill={props.color} />
  <path d="M5.83646 14.8287C5.94586 14.9381 6.09423 14.9995 6.24894 14.9995C6.40365 14.9995 6.55202 14.9381 6.66142 14.8287C6.77082 14.7193 6.83228 14.5709 6.83228 14.4162V7.41618C6.83228 7.26147 6.77082 7.1131 6.66142 7.0037C6.55202 6.8943 6.40365 6.83284 6.24894 6.83284C6.09423 6.83284 5.94586 6.8943 5.83646 7.0037C5.72707 7.1131 5.66561 7.26147 5.66561 7.41618V14.4162C5.66561 14.5709 5.72707 14.7193 5.83646 14.8287Z" fill={props.color} />
  <path d="M9.33646 14.8287C9.44586 14.9381 9.59423 14.9995 9.74894 14.9995C9.90365 14.9995 10.052 14.9381 10.1614 14.8287C10.2708 14.7193 10.3323 14.5709 10.3323 14.4162V9.36062C10.3323 9.20591 10.2708 9.05754 10.1614 8.94814C10.052 8.83875 9.90365 8.77729 9.74894 8.77729C9.59423 8.77729 9.44586 8.83875 9.33646 8.94814C9.22707 9.05754 9.16561 9.20591 9.16561 9.36062V14.4162C9.16561 14.5709 9.22707 14.7193 9.33646 14.8287Z" fill={props.color} />
  <path d="M12.8365 14.8287C12.9459 14.9381 13.0942 14.9995 13.2489 14.9995C13.4037 14.9995 13.552 14.9381 13.6614 14.8287C13.7708 14.7193 13.8323 14.5709 13.8323 14.4162V6.24951C13.8323 6.0948 13.7708 5.94643 13.6614 5.83703C13.552 5.72764 13.4037 5.66618 13.2489 5.66618C13.0942 5.66618 12.9459 5.72764 12.8365 5.83703C12.7271 5.94643 12.6656 6.0948 12.6656 6.24951V14.4162C12.6656 14.5709 12.7271 14.7193 12.8365 14.8287Z" fill={props.color} />
</svg>;

const SvgReportAd = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 2H14C14.2761 2 14.5 2.22386 14.5 2.5V11.5C14.5 11.7761 14.2761 12 14 12H2C1.72386 12 1.5 11.7761 1.5 11.5V2.5C1.5 2.22386 1.72386 2 2 2Z" stroke={props.color} />
  <path d="M6.20416 4.29999H5.98016C4.53116 4.29999 4.20216 4.50299 3.95716 5.86799L3.28516 9.64099H4.20916L4.56616 7.62499H6.46316L6.10616 9.64099H7.05116L7.63216 6.33699C7.69516 5.98699 7.73716 5.67199 7.73716 5.38499C7.73716 4.59399 7.33816 4.29999 6.20416 4.29999ZM4.69916 6.86199L4.84616 6.02199C4.97916 5.25199 5.07716 5.07699 5.88216 5.07699H6.06416C6.62416 5.07699 6.79216 5.15399 6.79216 5.54599C6.79216 5.72799 6.75016 6.00799 6.69416 6.32299L6.59616 6.86199H4.69916Z" fill={props.color} />
  <path d="M10.8298 4.34199H8.98879L8.05779 9.64099H10.2138C11.6138 9.64099 12.1108 9.28399 12.4258 7.47099L12.5798 6.58199C12.6428 6.23199 12.6708 5.93799 12.6708 5.67899C12.6708 4.67799 12.1668 4.34199 10.8298 4.34199ZM11.6488 6.58199L11.4948 7.47099C11.2918 8.65399 11.0748 8.86399 10.0248 8.86399H9.13579L9.78679 5.11899H10.6898C11.4458 5.11899 11.7328 5.21699 11.7328 5.81899C11.7328 6.01499 11.7048 6.25999 11.6488 6.58199Z" fill={props.color} />
  <path d="M3 14H13" stroke={props.color} strokeLinecap="round" />
</svg>;

const SvgMember = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8" cy="5" r="3.5" stroke={props.color} />
  <path d="M2 14C2 11.2386 4.68629 9 8 9C9.09982 9 10.1305 9.24659 11.0168 9.67705" stroke={props.color} strokeLinecap="round" />
  <path d="M6 14L8.63109 11.3515L10.6745 13.1573L14 10" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
</svg>;

const SvgStaff = (props: IconProps) => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.24922 1.26574L8.25659 1.26992L8.2641 1.27385L14.7592 4.67673C14.9084 4.76432 15 4.9243 15 5.10136V11.8987C15 12.0757 14.9084 12.2357 14.7591 12.3233L8.2641 15.7262L8.25659 15.7301L8.24922 15.7343C8.09492 15.8219 7.90508 15.8219 7.75078 15.7343L7.74341 15.7301L7.7359 15.7262L1.24086 12.3233C1.09163 12.2357 1 12.0757 1 11.8987V5.10136C1 4.9243 1.09162 4.76432 1.24085 4.67673L7.7359 1.27385L7.74341 1.26992L7.75078 1.26574C7.90508 1.1781 8.09492 1.1781 8.24922 1.26574Z" stroke={props.color} />
  <path d="M11 6.64752L8 8.25627L5 6.64752" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M8 8.5V11.9125" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
</svg>;

const SvgCustomMarketing = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 2.5C1.5 2.22386 1.72386 2 2 2H14C14.2761 2 14.5 2.22386 14.5 2.5V11.5C14.5 11.7761 14.2761 12 14 12H2C1.72386 12 1.5 11.7761 1.5 11.5V2.5Z" stroke={props.color} />
  <path d="M3 14H13" stroke={props.color} strokeLinecap="round" />
  <path d="M4 9.20001L6.6 6.76251L8.2 8.26251L12 4.70001" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" />
</svg>;

const SvgGroupMsgTool = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 13.4C7.38414 13.4 6.78917 13.3281 6.22597 13.1942C5.88806 13.1139 5.51711 13.1365 5.18119 13.2976L3.68242 14.0163L3.79621 13.0374C3.85866 12.5002 3.6234 12.0023 3.25569 11.673C2.15548 10.6878 1.5 9.37592 1.5 7.95C1.5 5.01316 4.33087 2.5 8 2.5C11.6691 2.5 14.5 5.01316 14.5 7.95C14.5 10.8868 11.6691 13.4 8 13.4Z" stroke={props.color} strokeLinecap="round" />
  <path d="M8.77057 5.5L10.5413 7.2708" stroke={props.color} strokeLinecap="round" />
  <path d="M8.77057 8.99323L9.77324 8.00906L10.5351 7.277" stroke={props.color} strokeLinecap="round" />
  <path d="M10.4563 7.25714H8.40001C6.90884 7.25714 5.70001 8.46597 5.70001 9.95714V9.95714" stroke={props.color} strokeLinecap="round" />
</svg>;

const SvgMarketingPlan = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_2979_54460" fill="white">
    <rect x="1" y="2" width="14" height="12" rx="0.730435" />
  </mask>
  <rect x="1" y="2" width="14" height="12" rx="0.730435" stroke={props.color} strokeWidth="1" strokeLinecap="round" />
  <line x1="2" y1="5.5" x2="14" y2="5.5" stroke={props.color} />
  <line x1="4.5" y1="1.5" x2="4.5" y2="3.5" stroke={props.color} strokeLinecap="round" />
  <line x1="11.5" y1="1.5" x2="11.5" y2="3.5" stroke={props.color} strokeLinecap="round" />
  <path d="M7.5 7.5L7.5 9.84753" stroke={props.color} strokeLinecap="round" />
  <path d="M9.23205 11L7.5 9.85714" stroke={props.color} strokeLinecap="round" />
</svg>;

const SvgChatTool = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 3C1.5 2.72386 1.72386 2.5 2 2.5H14C14.2761 2.5 14.5 2.72386 14.5 3V12C14.5 12.2761 14.2761 12.5 14 12.5H10.2038C9.77122 12.5 9.35974 12.6867 9.0749 13.0122L8.37629 13.8107C8.17708 14.0383 7.82292 14.0383 7.62371 13.8107L6.9251 13.0122C6.64026 12.6867 6.22878 12.5 5.79623 12.5H2C1.72386 12.5 1.5 12.2761 1.5 12V3Z" stroke={props.color} strokeLinecap="round" />
  <circle cx="4.75" cy="7.5" r="0.75" fill={props.color} />
  <circle cx="8" cy="7.5" r="0.75" fill={props.color} />
  <circle cx="11.25" cy="7.5" r="0.75" fill={props.color} />
</svg>;

const SvgCustomManage = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1.5" y="2.5" width="13" height="11" rx="0.5" stroke={props.color} strokeLinecap="round" />
  <circle cx="6" cy="7" r="1.5" stroke={props.color} strokeLinecap="round" />
  <path d="M8.5 11C8.5 9.61929 7.38071 8.5 6 8.5C4.61929 8.5 3.5 9.61929 3.5 11" stroke={props.color} strokeLinecap="round" />
  <line x1="9.5" y1="6.5" x2="12.5" y2="6.5" stroke={props.color} strokeLinecap="round" />
  <line x1="9.5" y1="8.5" x2="11.5" y2="8.5" stroke={props.color} strokeLinecap="round" />
</svg>;


const SvgCustomGrouManage = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9.75553" cy="6.05722" r="2.53446" stroke={props.color} />
  <path d="M3.88757 13.6875C3.88757 11.125 6.84436 8.58368 9.91938 8.58368" stroke={props.color} strokeLinecap="round" />
  <path d="M5.33001 6.88565C4.39992 6.51026 3.74359 5.59901 3.74359 4.53446C3.74359 3.13471 4.87831 2 6.27805 2C6.61129 2 6.92952 2.06432 7.22098 2.18121" stroke={props.color} strokeLinecap="round" />
  <path d="M2 10.3537C2 8.80715 3.53247 7.27157 5.33401 6.86865" stroke={props.color} strokeLinecap="round" />
  <rect x="8.69031" y="11.2529" width="4" height="1" rx="0.5" fill={props.color} />
  <path d="M8.69031 14.2529C8.69031 13.9768 8.91417 13.7529 9.19031 13.7529H14.1903C14.4665 13.7529 14.6903 13.9768 14.6903 14.2529C14.6903 14.5291 14.4664 14.7529 14.1903 14.7529H9.19031C8.91417 14.7529 8.69031 14.5291 8.69031 14.2529Z" fill={props.color} />
</svg>;

const SvgAccountManage = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8.5" cy="5.5" r="4" stroke={props.color} />
  <path d="M1.5 15C1.5 12.2386 4.68629 9.5 8 9.5" stroke={props.color} strokeLinecap="round" />
  <rect x="9" y="11.5" width="4" height="1" rx="0.5" fill={props.color} />
  <path d="M9 14.5C9 14.2239 9.22386 14 9.5 14H14.5C14.7761 14 15 14.2239 15 14.5V14.5C15 14.7761 14.7761 15 14.5 15H9.5C9.22386 15 9 14.7761 9 14.5V14.5Z" fill={props.color} />
</svg>;

const SvgSetting = (props: IconProps) => <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75498 1.27589C10.0594 1.10465 10.4311 1.10465 10.7355 1.27589L17.7355 5.21339C18.0504 5.3905 18.2452 5.72369 18.2452 6.08496V13.9153C18.2452 14.2765 18.0504 14.6097 17.7355 14.7868L10.7355 18.7243C10.4311 18.8956 10.0594 18.8956 9.75498 18.7243L2.75498 14.7868C2.4401 14.6097 2.24524 14.2765 2.24524 13.9153V6.08496C2.24524 5.72369 2.4401 5.3905 2.75498 5.21339L9.75498 1.27589ZM10.0024 2.33139C10.1534 2.24744 10.3371 2.24744 10.4881 2.33139L16.9881 5.9438C17.1468 6.032 17.2452 6.19929 17.2452 6.38084V13.5731C17.2452 13.7547 17.1468 13.922 16.9881 14.0102L10.4881 17.6226C10.3371 17.7065 10.1534 17.7065 10.0024 17.6226L3.50235 14.0102C3.34366 13.922 3.24524 13.7547 3.24524 13.5731V6.38084C3.24524 6.19929 3.34366 6.032 3.50235 5.9438L10.0024 2.33139ZM13.2452 10.0001C13.2452 11.657 11.9021 13.0001 10.2452 13.0001C8.58839 13.0001 7.24524 11.657 7.24524 10.0001C7.24524 8.34326 8.58839 7.00011 10.2452 7.00011C11.9021 7.00011 13.2452 8.34326 13.2452 10.0001ZM12.2452 10.0001C12.2452 11.1047 11.3498 12.0001 10.2452 12.0001C9.14067 12.0001 8.24524 11.1047 8.24524 10.0001C8.24524 8.89554 9.14067 8.00011 10.2452 8.00011C11.3498 8.00011 12.2452 8.89554 12.2452 10.0001Z" fill={props.color} />
</svg>
;

const SvgConversation = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 7.5V4.86522C14 4.63531 13.8918 4.41882 13.7078 4.28087L10.1948 1.64609C10.0683 1.55126 9.91457 1.5 9.75652 1.5H2.73043C2.32703 1.5 2 1.82703 2 2.23043V13.7696C2 14.173 2.32703 14.5 2.73043 14.5H7" stroke={props.color} strokeLinecap="round" />
  <path d="M4.5 6H11" stroke={props.color} strokeLinecap="round" />
  <path d="M4.5 9H7.5" stroke={props.color} strokeLinecap="round" />
  <path d="M13.4142 13.9142L14.4142 14.9142" stroke={props.color} strokeLinecap="round" />
  <circle cx="11.75" cy="11.75" r="2.25" stroke={props.color} strokeLinecap="round" />
</svg>;

export const SvgHelp = (props: IconProps) => <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2452 10C19.2452 14.9706 15.2158 19 10.2452 19C5.27468 19 1.24524 14.9706 1.24524 10C1.24524 5.02944 5.27468 1 10.2452 1C15.2158 1 19.2452 5.02944 19.2452 10ZM18.2452 10C18.2452 14.4183 14.6635 18 10.2452 18C5.82696 18 2.24524 14.4183 2.24524 10C2.24524 5.58172 5.82696 2 10.2452 2C14.6635 2 18.2452 5.58172 18.2452 10ZM12.5792 5.742C12.0332 5.238 11.3192 5 10.4232 5C9.41524 5 8.63124 5.308 8.07124 5.924C7.62065 6.39712 7.3604 6.99714 7.27591 7.72406C7.24065 8.02743 7.49283 8.276 7.79824 8.276C8.10365 8.276 8.3446 8.02689 8.38905 7.72473C8.45196 7.29707 8.59335 6.94586 8.81324 6.666C9.14924 6.204 9.66724 5.98 10.3672 5.98C10.9552 5.98 11.4172 6.134 11.7392 6.47C12.0472 6.778 12.2152 7.212 12.2152 7.772C12.2152 8.164 12.0752 8.528 11.7952 8.878C11.7112 8.99 11.5432 9.158 11.3192 9.382C10.5632 10.054 10.1012 10.586 9.90524 11.006C9.73724 11.356 9.65324 11.762 9.65324 12.224C9.65324 12.4018 9.7974 12.546 9.97524 12.546H10.4512C10.6291 12.546 10.7732 12.4018 10.7732 12.224C10.7732 11.846 10.8572 11.51 11.0392 11.202C11.1792 10.95 11.3892 10.698 11.6972 10.432C12.3132 9.886 12.6912 9.522 12.8312 9.354C13.1812 8.892 13.3632 8.346 13.3632 7.716C13.3632 6.876 13.0972 6.218 12.5792 5.742ZM10.8292 13.764C10.6612 13.596 10.4512 13.526 10.2132 13.526C9.97524 13.526 9.77924 13.596 9.61124 13.764C9.44324 13.918 9.37324 14.114 9.37324 14.352C9.37324 14.59 9.44324 14.786 9.61124 14.954C9.77924 15.108 9.97524 15.192 10.2132 15.192C10.4512 15.192 10.6472 15.108 10.8152 14.954C10.9832 14.8 11.0672 14.604 11.0672 14.352C11.0672 14.114 10.9832 13.918 10.8292 13.764Z" fill={props.color} />
</svg>;

const SvgWorkBench = (props: IconProps) => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.75 6.5L5.25 6.5V5.5H7.75V6.5Z" fill={props.color} />
  <path d="M5.25 7.5V8.5H10.25V7.5L5.25 7.5Z" fill={props.color} />
  <path fillRule="evenodd" clipRule="evenodd" d="M1.5 1.5H19.5V15.5H12.5V18.5H15.25V19.5H5.25V18.5H7.5V15.5H1.5V1.5ZM11.5 18.5V15.5H8.5V18.5H11.5ZM18.5 14.5V2.5H2.5V14.5H18.5Z" fill={props.color} />
</svg>;

const SvgAdService = (props: IconProps) => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.8325 18 18 13.8325 18 9C18 4.16751 13.8325 0 9 0C4.16751 0 0 4.16751 0 9C0 13.8325 4.16751 18 9 18ZM8.92205 17C13.0642 17 17 13.1421 17 9C17 4.85786 13.1421 1 9 1C4.85786 1 1 4.85786 1 9C1 13.1421 4.77991 17 8.92205 17Z" fill={props.color} />
  <path d="M8.17722 11.5C7.95546 11.5 7.75804 11.3595 7.68535 11.15L7.4945 10.6H5.42011L5.22857 11.152C5.15629 11.3603 4.96 11.5 4.73952 11.5C4.38016 11.5 4.13013 11.1428 4.25315 10.8052L5.60384 7.09771C5.73459 6.73883 6.07579 6.5 6.45775 6.5C6.83927 6.5 7.18018 6.73829 7.31125 7.09659L8.66617 10.8005C8.79043 11.1402 8.53894 11.5 8.17722 11.5ZM5.74725 9.67857H7.1748L6.46102 7.63571L5.74725 9.67857Z" fill={props.color} />
  <path d="M11.4944 6.5C12.9293 6.5 14 7.59286 14 9C14 10.4071 12.9293 11.5 11.4944 11.5H10.4274C9.87512 11.5 9.4274 11.0523 9.4274 10.5V7.5C9.4274 6.94772 9.87512 6.5 10.4274 6.5H11.4944ZM11.4944 10.5571C12.394 10.5571 13.0186 9.92143 13.0186 9C13.0186 8.07857 12.394 7.44286 11.4944 7.44286H10.4534V10.5571H11.4944Z" fill={props.color} />
</svg>
;

const  SvgAdServiceOver = (props: IconProps) =>  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M10 19.5C14.8325 19.5 19 15.3325 19 10.5C19 5.66751 14.8325 1.5 10 1.5C5.16751 1.5 1 5.66751 1 10.5C1 15.3325 5.16751 19.5 10 19.5Z" fill={props.color} />
  <path d="M8.80678 13L8.4945 12.1H6.42011L6.10783 13H5L6.8216 8H8.09301L9.92205 13H8.80678ZM6.74725 11.1786H8.1748L7.46102 9.13571L6.74725 11.1786Z" fill="#FFFFFF"/>
  <path d="M12.4944 8C13.9293 8 15 9.09286 15 10.5C15 11.9071 13.9293 13 12.4944 13H10.4274V8H12.4944ZM12.4944 12.0571C13.394 12.0571 14.0186 11.4214 14.0186 10.5C14.0186 9.57857 13.394 8.94286 12.4944 8.94286H11.4534V12.0571H12.4944Z" fill="#FFFFFF"/>
</svg>;


const SvgRiseEngine = (props: IconProps) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1H2V18H1L1 1Z" fill={props.color} />
  <path d="M19 18V19H1L1 18L19 18Z" fill={props.color}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M18 4H14V16H18V4ZM17 5H15V15H17V5Z" fill={props.color} />
  <path fillRule="evenodd" clipRule="evenodd" d="M13 7H9V16H13V7ZM12 8H10V15H12V8Z" fill={props.color} />
  <path fillRule="evenodd" clipRule="evenodd" d="M8 10H4V16H8V10ZM7 11H5V15H7V11Z" fill={props.color} />
</svg>;

const SvgRiseEngineOver = (props: IconProps) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 2.5C1 2.22386 1.22386 2 1.5 2C1.77614 2 2 2.22386 2 2.5V18H1L1 2.5Z"  fill={props.color} />
  <path d="M17.5 18C17.7761 18 18 18.2239 18 18.5C18 18.7761 17.7761 19 17.5 19H2C1.44771 19 1 18.5523 1 18L17.5 18Z"  fill={props.color} />
  <path d="M14 5C14 4.44772 14.4477 4 15 4H17C17.5523 4 18 4.44772 18 5V15C18 15.5523 17.5523 16 17 16H15C14.4477 16 14 15.5523 14 15V5Z"  fill={props.color} />
  <path d="M9 8C9 7.44772 9.44772 7 10 7H12C12.5523 7 13 7.44772 13 8V15C13 15.5523 12.5523 16 12 16H10C9.44772 16 9 15.5523 9 15V8Z"  fill={props.color} />
  <path d="M4 11C4 10.4477 4.44772 10 5 10H7C7.55228 10 8 10.4477 8 11V15C8 15.5523 7.55228 16 7 16H5C4.44772 16 4 15.5523 4 15V11Z"  fill={props.color} />
</svg>;


const SvgMarketingDiscover = (props: IconProps) => <svg width="16" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="10" cy="10.0005" r="8.35986" stroke={props.color} stroke-width="1.25" />
  <path d="M8.30558 8.08013L13.2094 6.31059C13.5081 6.2028 13.7972 6.4919 13.6894 6.79061L11.9199 11.6944C11.882 11.7994 11.7994 11.882 11.6944 11.9199L6.79061 13.6894C6.4919 13.7972 6.2028 13.5081 6.31059 13.2094L8.08013 8.30558C8.118 8.20063 8.20063 8.118 8.30558 8.08013Z" stroke={props.color} stroke-width="1.25" />
  <circle cx="10" cy="10" r="1" fill={props.color} />
</svg>;

const SvgMarketingSandtable = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_5648_187038" fill="white">
    <rect x="2.5" y="3" width="15" height="12" rx="1" />
  </mask>
  <rect x="2.5" y="3" width="15" height="12" rx="1" stroke={props.color} stroke-width="2.5" mask="url(#path-1-inside-1_5648_187038)" />
  <path d="M6 11L8.75 8.33333L11.25 9.66667L14 7" stroke={props.color} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
  <rect x="1" y="3" width="18" height="1" rx="0.5" fill={props.color} />
  <path d="M7.5 14.5L4.40244 18.0079" stroke={props.color} stroke-width="1.25" stroke-linecap="round" />
  <path d="M12.5 14.5L15.5976 18.0079" stroke={props.color} stroke-width="1.25" stroke-linecap="round" />
</svg>;

const SvgShopingHelp = (props: IconProps) => <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M1.00002 1.5H19V16.5H9.34891L6.30929 18.8929L5.50002 19.53V18.5V16.5H1.00002V1.5ZM9.00002 15.5V15.502L6.50002 17.47V15.5H6.00002H5.50002H2V2.5H18V15.5H9.00002ZM4.99034 5.9993C4.96309 5.7245 5.18958 5.5 5.46572 5.5C5.74186 5.5 5.96244 5.72498 5.99654 5.99901C6.24215 7.97264 7.92557 9.5 9.96572 9.5C12.0059 9.5 13.6893 7.97264 13.9349 5.99901C13.969 5.72498 14.1896 5.5 14.4657 5.5C14.7419 5.5 14.9683 5.7245 14.9411 5.9993C14.6906 8.5263 12.5586 10.5 9.96572 10.5C7.37281 10.5 5.24088 8.5263 4.99034 5.9993Z" fill={props.color} />
</svg>;

const SvgShopingHelpOver = (props: IconProps) => <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M0 0.495972H17.9999V15.496H8.82102L5 18.504V15.496H0V0.495972ZM3.99026 4.99527C3.96302 4.72048 4.1895 4.49597 4.46565 4.49597C4.74179 4.49597 4.96237 4.72095 4.99647 4.99498C5.24208 6.96862 6.92549 8.49597 8.96565 8.49597C11.0058 8.49597 12.6892 6.96862 12.9348 4.99498C12.9689 4.72095 13.1895 4.49597 13.4656 4.49597C13.7418 4.49597 13.9683 4.72048 13.941 4.99527C13.6905 7.52227 11.5586 9.49597 8.96565 9.49597C6.37273 9.49597 4.2408 7.52227 3.99026 4.99527Z" fill={props.color} />
</svg>;

const SvgAllRoad = (props: IconProps) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M5.55007 2.775C5.55007 3.20302 5.20309 3.55 4.77507 3.55C4.34704 3.55 4.00007 3.20302 4.00007 2.775C4.00007 2.34698 4.34704 2 4.77507 2C5.20309 2 5.55007 2.34698 5.55007 2.775ZM6.44934 3.36589C6.20589 4.0557 5.5482 4.55 4.77507 4.55C3.79476 4.55 3.00007 3.75531 3.00007 2.775C3.00007 1.79469 3.79476 1 4.77507 1C5.54762 1 6.2049 1.49355 6.44879 2.18255H13.9013C16.1887 2.18255 18.043 4.03684 18.043 6.32422C18.043 8.6116 16.1887 10.4659 13.9013 10.4659H11.7662C11.5229 11.156 10.8651 11.6506 10.0917 11.6506C9.31838 11.6506 8.66054 11.156 8.41726 10.4659H6.73326V10.4655H6.14167C4.50782 10.4655 3.18333 11.79 3.18333 13.4238C3.18333 15.0577 4.50782 16.3822 6.14167 16.3822H13.5279C13.7719 15.6935 14.4291 15.2002 15.2014 15.2002C16.1817 15.2002 16.9764 15.9949 16.9764 16.9752C16.9764 17.9555 16.1817 18.7502 15.2014 18.7502C14.428 18.7502 13.7702 18.2556 13.5269 17.5655H6.14167C3.85429 17.5655 2 15.7112 2 13.4238C2 11.1364 3.85429 9.28216 6.14167 9.28216H8.41836C8.66253 8.59367 9.31955 8.10059 10.0917 8.10059C10.8639 8.10059 11.521 8.59367 11.7651 9.28216H13.8333V9.28255H13.9013C15.5352 9.28255 16.8596 7.95806 16.8596 6.32422C16.8596 4.69038 15.5352 3.36589 13.9013 3.36589H6.44934ZM15.9764 16.9752C15.9764 17.4032 15.6294 17.7502 15.2014 17.7502C14.7734 17.7502 14.4264 17.4032 14.4264 16.9752C14.4264 16.5472 14.7734 16.2002 15.2014 16.2002C15.6294 16.2002 15.9764 16.5472 15.9764 16.9752ZM10.0917 10.6506C10.5198 10.6506 10.8667 10.3036 10.8667 9.87559C10.8667 9.44757 10.5198 9.10059 10.0917 9.10059C9.66372 9.10059 9.31674 9.44757 9.31674 9.87559C9.31674 10.3036 9.66372 10.6506 10.0917 10.6506Z" fill={props.color} />
</svg>;

const SvgAllRoadOver = (props: IconProps) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.44934 3.36589C6.20589 4.0557 5.5482 4.55 4.77507 4.55C3.79476 4.55 3.00007 3.75531 3.00007 2.775C3.00007 1.79469 3.79476 1 4.77507 1C5.54762 1 6.2049 1.49355 6.44879 2.18255H13.9013C16.1887 2.18255 18.043 4.03684 18.043 6.32422C18.043 8.6116 16.1887 10.4659 13.9013 10.4659H11.7662C11.5229 11.156 10.8651 11.6506 10.0917 11.6506C9.31838 11.6506 8.66054 11.156 8.41726 10.4659L6.14167 10.4655C4.50782 10.4655 3.18333 11.79 3.18333 13.4238C3.18333 15.0577 4.50782 16.3822 6.14167 16.3822H13.5279C13.7719 15.6935 14.4291 15.2002 15.2014 15.2002C16.1817 15.2002 16.9764 15.9949 16.9764 16.9752C16.9764 17.9555 16.1817 18.7502 15.2014 18.7502C14.428 18.7502 13.7702 18.2556 13.5269 17.5655H6.14167C3.85429 17.5655 2 15.7112 2 13.4238C2 11.1364 3.85429 9.28216 6.14167 9.28216H8.41836C8.66253 8.59367 9.31955 8.10059 10.0917 8.10059C10.8639 8.10059 11.521 8.59367 11.7651 9.28216H13.8333L13.9013 9.28255C15.5352 9.28255 16.8596 7.95806 16.8596 6.32422C16.8596 4.69038 15.5352 3.36589 13.9013 3.36589H6.44934Z" fill={props.color} />
</svg>;

const SvgAILab = (props: IconProps) => <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.25 2.375C6.25 2.02982 6.52982 1.75 6.875 1.75C7.22018 1.75 7.5 2.02982 7.5 2.375V5.5H12.5V2.375C12.5 2.02982 12.7798 1.75 13.125 1.75C13.4702 1.75 13.75 2.02982 13.75 2.375V5.5H17.5V19.25H2.5V5.5H6.25V2.375ZM3.75 6.75H16.25V18H3.75V6.75ZM6.875 11.75C7.22018 11.75 7.5 11.4702 7.5 11.125C7.5 10.7798 7.22018 10.5 6.875 10.5C6.52982 10.5 6.25 10.7798 6.25 11.125C6.25 11.4702 6.52982 11.75 6.875 11.75ZM13.75 11.125C13.75 11.4702 13.4702 11.75 13.125 11.75C12.7798 11.75 12.5 11.4702 12.5 11.125C12.5 10.7798 12.7798 10.5 13.125 10.5C13.4702 10.5 13.75 10.7798 13.75 11.125ZM11.25 14.25H8.75V15.5H11.25V14.25Z" fill={props.color} />
  <rect x="18.75" y="10.5" width="1.25" height="3.75" fill={props.color} />
  <rect y="10.5" width="1.25" height="3.75" fill={props.color} />
</svg>;

const SvgAILabOver = (props: IconProps) => <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.25 2.375C6.25 2.02982 6.52982 1.75 6.875 1.75C7.22018 1.75 7.5 2.02982 7.5 2.375V5.5H12.5V2.375C12.5 2.02982 12.7798 1.75 13.125 1.75C13.4702 1.75 13.75 2.02982 13.75 2.375V5.5H16.5C17.0523 5.5 17.5 5.94772 17.5 6.5V18.25C17.5 18.8023 17.0523 19.25 16.5 19.25H3.5C2.94772 19.25 2.5 18.8023 2.5 18.25V6.5C2.5 5.94772 2.94772 5.5 3.5 5.5H6.25V2.375ZM6.875 11.75C7.22018 11.75 7.5 11.4702 7.5 11.125C7.5 10.7798 7.22018 10.5 6.875 10.5C6.52982 10.5 6.25 10.7798 6.25 11.125C6.25 11.4702 6.52982 11.75 6.875 11.75ZM13.75 11.125C13.75 11.4702 13.4702 11.75 13.125 11.75C12.7798 11.75 12.5 11.4702 12.5 11.125C12.5 10.7798 12.7798 10.5 13.125 10.5C13.4702 10.5 13.75 10.7798 13.75 11.125ZM9.375 14.25C9.02982 14.25 8.75 14.5298 8.75 14.875C8.75 15.2202 9.02982 15.5 9.375 15.5H10.625C10.9702 15.5 11.25 15.2202 11.25 14.875C11.25 14.5298 10.9702 14.25 10.625 14.25H9.375Z" fill={props.color} />
  <rect x="18.75" y="10.5" width="1.25" height="3.75" rx="0.625" fill={props.color} />
  <rect y="10.5" width="1.25" height="3.75" rx="0.625" fill={props.color} />
</svg>;


const SvgOpsTool = (props: IconProps) => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M19.25 5.5H15.3082C14.7532 3.34347 12.7955 1.75 10.4657 1.75C8.13593 1.75 6.1783 3.34347 5.62325 5.5H1.75V19.25H19.25V5.5ZM10.4657 3C8.83296 3 7.44391 4.04351 6.92911 5.5H14.0023C13.4876 4.04351 12.0985 3 10.4657 3ZM18 6.75H14.8407H3V10.5H5.5V9.25H6.75V10.5H14.25V9.25H15.5V10.5H18V6.75ZM14.25 11.75V13H15.5V11.75H18V18H3V11.75H5.5V13H6.75V11.75H14.25Z" fill={props.color} />
</svg>;

const SvgOpsToolOver = (props: IconProps) => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M15.9951 4.78572H20.5L20.5 10.4998H16.2143V9.07136H14.7857V10.4998H6.21429V9.07136H4.78572V10.4998H0.500009V11.9284H4.78572V13.3571H6.21429V11.9284H14.7857V13.3571H16.2143V11.9284H20.5L20.5 20.5H0.5V4.78572H4.92657C5.56092 2.3211 7.79821 0.5 10.4608 0.5C13.1235 0.5 15.3608 2.3211 15.9951 4.78572ZM6.41906 4.78572C7.00745 3.12126 8.59488 1.92878 10.4608 1.92878C12.3268 1.92878 13.9142 3.12126 14.5026 4.78572H6.41906Z" fill={props.color} />
</svg>;

export const InconDataSource = () => <div><svg width="16" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.5 2L10.5 6" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <path d="M10.5 11V18" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <circle cx="10.5" cy="7.5" r="1.5" stroke="currentColor" stroke-width="1.25"/>
  <path d="M16.5 18L16.5 14" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <path d="M16.5 9V2" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <circle cx="16.5" cy="12.5" r="1.5" transform="rotate(180 16.5 12.5)" stroke="currentColor" stroke-width="1.25"/>
  <path d="M4.5 18L4.5 14" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <path d="M4.5 9V2" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
  <circle cx="4.5" cy="12.5" r="1.5" transform="rotate(180 4.5 12.5)" stroke="currentColor" stroke-width="1.25"/>
</svg></div>;


export const SvgButtonLeftArrow = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.35815 7.7684L1.41601 7.69915L6.41601 2.69915C6.61127 2.50389 6.92785 2.50389 7.12312 2.69915C7.29668 2.87272 7.31597 3.14214 7.18097 3.33701L7.12312 3.40626L2.97756 7.5527L14.0533 7.5527C14.3294 7.55273 14.5533 7.7766 14.5533 8.05274C14.5533 8.2982 14.3763 8.50234 14.1431 8.54465L14.0532 8.5527H2.97556L7.12312 12.6991C7.29668 12.8727 7.31597 13.1421 7.18097 13.337L7.12312 13.4063C6.94955 13.5798 6.68013 13.5991 6.48526 13.4641L6.41601 13.4063L1.41601 8.40626C1.24244 8.23269 1.22316 7.96326 1.35815 7.7684Z" fill={props.color} /></svg>;

export const SvgClose = (props: IconProps) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.23805 6.30181C6.8921 6.04757 6.44797 6.03497 6.18861 6.29205C5.89684 6.58127 5.9494 7.1023 6.30601 7.45579L10.8904 12L6.30601 16.5442L6.19846 16.6677C5.94197 17.0106 5.92926 17.4509 6.18861 17.7079C6.48039 17.9972 7.00601 17.9451 7.36263 17.5916L11.947 13.0474L16.6374 17.6967L16.7619 17.8033C17.1079 18.0575 17.552 18.0701 17.8114 17.813C18.1032 17.5238 18.0506 17.0028 17.694 16.6493L13.0036 12L17.694 7.3507L17.8015 7.22721C18.058 6.88428 18.0707 6.44405 17.8114 6.18696C17.5196 5.89774 16.994 5.94984 16.6374 6.30333L11.947 10.9526L7.36263 6.40843L7.23805 6.30181Z" fill={props.color}/>
</svg>;

export const SvgLike = (props: IconProps) => <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="m4.08 6.43 2.34-5.25a1.75 1.75 0 0 1 1.75 1.75v2.33h3.3a1.17 1.17 0 0 1 1.16 1.35l-.8 5.25a1.17 1.17 0 0 1-1.17.99H4.08m0-6.42v6.42m0-6.42H2.33A1.17 1.17 0 0 0 1.17 7.6v4.08a1.17 1.17 0 0 0 1.16 1.17h1.75" stroke={props.color}  stroke-width="1.03" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h14v14H0z"/></clipPath></defs></svg>;

export const SvgLikeActive = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"><g clip-path="url(#a)"><path fill={props.color} d="M8.166 5.265V2.932a1.75 1.75 0 0 0-1.75-1.75l-2.333 5.25v6.416h6.58a1.166 1.166 0 0 0 1.166-.991l.805-5.25a1.167 1.167 0 0 0-1.166-1.342H8.166Zm-4.083 7.583h-1.75a1.167 1.167 0 0 1-1.167-1.166V7.598a1.167 1.167 0 0 1 1.167-1.166h1.75" /><path stroke={props.color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.031" d="m4.083 6.432 2.333-5.25a1.75 1.75 0 0 1 1.75 1.75v2.333h3.302a1.167 1.167 0 0 1 1.166 1.342l-.805 5.25a1.166 1.166 0 0 1-1.166.991h-6.58m0-6.416v6.416m0-6.416h-1.75a1.167 1.167 0 0 0-1.167 1.166v4.084a1.167 1.167 0 0 0 1.167 1.166h1.75" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h14v14H0z"/></clipPath></defs></svg>;

export const SvgDislike = (props: IconProps) => <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="m4.08 7.57 2.34 5.25a1.75 1.75 0 0 0 1.75-1.75V8.74h3.3a1.17 1.17 0 0 0 1.16-1.35l-.8-5.25a1.17 1.17 0 0 0-1.17-.99H4.08m0 6.42V1.15m0 6.42H2.33A1.17 1.17 0 0 1 1.17 6.4V2.32a1.17 1.17 0 0 1 1.16-1.17h1.75" stroke={props.color} stroke-width="1.03" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="a"><path fill="#fff" transform="matrix(1 0 0 -1 0 14)" d="M0 0h14v14H0z"/></clipPath></defs></svg>;

export const SvgDislikeActive = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"><g clip-path="url(#a)"><path fill={props.color} d="M8.166 8.735v2.333a1.75 1.75 0 0 1-1.75 1.75l-2.333-5.25V1.152h6.58a1.167 1.167 0 0 1 1.166.991l.805 5.25a1.167 1.167 0 0 1-1.166 1.342H8.166ZM4.083 1.152h-1.75a1.167 1.167 0 0 0-1.167 1.166v4.084a1.167 1.167 0 0 0 1.167 1.166h1.75" /><path stroke={props.color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.031" d="m4.083 7.568 2.333 5.25a1.75 1.75 0 0 0 1.75-1.75V8.735h3.302a1.167 1.167 0 0 0 1.166-1.342l-.805-5.25a1.166 1.166 0 0 0-1.166-.991h-6.58m0 6.416V1.152m0 6.416h-1.75a1.167 1.167 0 0 1-1.167-1.166V2.318a1.167 1.167 0 0 1 1.167-1.166h1.75" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h14v14H0z" transform="matrix(1 0 0 -1 0 14)"/></clipPath></defs></svg>;

export const SvgCopy = (props: IconProps) => <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.52 1.04a.51.51 0 0 0-.38-.16H4.96c-.85 0-1.53.68-1.53 1.53v7.14c0 .85.68 1.54 1.53 1.54h6.12c.85 0 1.54-.69 1.54-1.54v-5.1a.51.51 0 0 0-.15-.36l-1.53-1.53-1.42-1.52Zm-4.56.86h3.57v2.55c0 .28.23.51.51.51h2.55v4.6c0 .28-.22.5-.5.5H4.95a.51.51 0 0 1-.51-.5V2.4c0-.28.23-.51.5-.51Zm4.6.72 1.31 1.32H9.55V2.62Zm-6.64 10.5c-.85 0-1.53-.68-1.53-1.52V4.96a.51.51 0 1 1 1.02 0v6.64c0 .28.23.5.5.5H7a.51.51 0 1 1 0 1.03H2.92Z" fill={props.color} /></svg>;

// AI 品推广文案
export const IconAIProductPromo = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__product-promo" style={{ width: size, height: size }}></div>
);
// AI 文档信息提取
export const IconAIPdfAnalyze = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__pdf-analyze" style={{ width: size, height: size }}></div>
);
// AI 公众号内容总结
export const IconAIOfficialAccountArticle = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__official-account-article" style={{ width: size, height: size }}></div>
);
// AI 智能客服
export const IconAICustomerServide = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__customer-service" style={{ width: size, height: size }}></div>
);
// AI 文档信息提取
export const IconAIOpeningTalk = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__opening-talk" style={{ width: size, height: size }}></div>
);
// AI 文档信息提取
export const IconAICustomerCare = ({ size = 32 }: IconProps) => (
  <div className="zhb-icon zhb-icon__customer-care" style={{ width: size, height: size }}></div>
);

export const SvgInfoCopy = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4655 2.15884C10.371 2.05752 10.2386 2 10.1 2H9.99988H6C5.17157 2 4.5 2.67157 4.5 3.5V10.5C4.5 11.3284 5.17157 12 6 12H12C12.8284 12 13.5 11.3284 13.5 10.5V5.50012V5.5C13.5 5.49171 13.4998 5.48345 13.4994 5.47521C13.4967 5.42143 13.4854 5.36884 13.4662 5.31935C13.4531 5.28544 13.4363 5.25299 13.416 5.2226C13.3979 5.19542 13.377 5.16989 13.3536 5.14645L11.8596 3.65254L10.4655 2.15884ZM6 3H9.49999V5.50001C9.49999 5.77616 9.72384 6.00001 9.99999 6.00001H12.5V10.5C12.5 10.7761 12.2761 11 12 11H6C5.72386 11 5.5 10.7761 5.5 10.5V3.5C5.5 3.22386 5.72386 3 6 3ZM10.5 3.70712L11.7929 5.00001H10.5V3.70712ZM4 14C3.17157 14 2.5 13.3284 2.5 12.5V6C2.5 5.72386 2.72386 5.5 3 5.5C3.27614 5.5 3.5 5.72386 3.5 6V12.5C3.5 12.7761 3.72386 13 4 13H8C8.27614 13 8.5 13.2239 8.5 13.5C8.5 13.7761 8.27614 14 8 14H4Z" fill="currentColor"/>
</svg>;


const SvgLinkFunnel = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
  <path d="M8.75 6.5625H5V5.3125H8.75V6.5625Z" fill={props.color}/>
  <path d="M1.87531 3.12517C1.87521 2.77993 2.15506 2.5 2.50031 2.5H17.5003C17.8454 2.5 18.1252 2.77972 18.1253 3.12483L18.1265 8.74985C18.1266 9.0951 17.8467 9.37502 17.5015 9.37502H2.50149C2.15638 9.37502 1.87659 9.0953 1.87649 8.75019L1.87531 3.12517ZM3.12632 8.12502H16.8763L16.8755 3.75H3.12547L3.12632 8.12502Z" fill={props.color}/>
  <path d="M5.00014 14.6875H8.75014V13.4375H5.00014V14.6875Z" fill={props.color}/>
  <path d="M1.87666 11.25C1.87666 10.9048 2.15648 10.625 2.50166 10.625H17.5017C17.8468 10.625 18.1267 10.9048 18.1267 11.25V16.875C18.1267 17.2202 17.8468 17.5 17.5017 17.5H2.50166C2.15648 17.5 1.87666 17.2202 1.87666 16.875V11.25ZM3.12666 16.25H16.8767V11.875H3.12666V16.25Z" fill={props.color}/>
</svg>;

const SvgAiShopping = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
    d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1V13C0.5 13.2761 0.723858 13.5 1 13.5H4.1V15C4.1 15.1733 4.18973 15.3342 4.33713 15.4253C4.48454 15.5164 4.66861 15.5247 4.82361 15.4472L8.71803 13.5H17C17.2761 13.5 17.5 13.2761 17.5 13V1C17.5 0.723858 17.2761 0.5 17 0.5H1ZM1.5 12.5V1.5H16.5V12.5H8.6C8.52238 12.5 8.44582 12.5181 8.37639 12.5528L5.1 14.191V13C5.1 12.7239 4.87614 12.5 4.6 12.5H1.5ZM8.90871 10.272H10.2025L7.8826 3.91904H6.91003L4.59906 10.272H5.89285L6.27652 9.14772H8.53396L8.90871 10.272ZM7.42755 5.7928L8.20382 8.10377H6.62451L7.42755 5.7928ZM10.985 10.272H12.2252V3.91904H10.985V10.272Z"
    fill={props.color} />
</svg>;

const SvgShoppingSop = (props: IconProps) => <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
    d="M4.5 1.46953C4.5 1.19339 4.72386 0.969528 5 0.969528H11C11.2761 0.969528 11.5 1.19339 11.5 1.46953V5.46953C11.5 5.74567 11.2761 5.96953 11 5.96953H8.5V8.46953H12.5829C12.8591 8.46953 13.0829 8.69339 13.0829 8.96953V11.7195H15.5C15.7761 11.7195 16 11.9434 16 12.2195V16.2195C16 16.4957 15.7761 16.7195 15.5 16.7195H9.5C9.22386 16.7195 9 16.4957 9 16.2195V12.2195C9 11.9434 9.22386 11.7195 9.5 11.7195H12.0829V9.46953H3.91626V11.7195H6.5C6.77614 11.7195 7 11.9434 7 12.2195V16.2195C7 16.4957 6.77614 16.7195 6.5 16.7195H0.5C0.223858 16.7195 0 16.4957 0 16.2195V12.2195C0 11.9434 0.223858 11.7195 0.5 11.7195H2.91626V8.96953C2.91626 8.69339 3.14012 8.46953 3.41626 8.46953H7.5V5.96953H5C4.72386 5.96953 4.5 5.74567 4.5 5.46953V1.46953ZM5.5 1.96953V4.96953H10.5V1.96953H5.5ZM1 12.7195V15.7195H6V12.7195H1ZM10 15.7195V12.7195H15V15.7195H10Z"
    fill={props.color} />
</svg>;


/**
 * span为hover后的效果
 */
const IconReportDashbord = () => <>
  <div><SvgReportDashbord color="currentColor" /></div>
</>;

const IconExpanseUp = () => <>
  <div><ArrowUpOne color="currentColor" /></div>
</>;

const IconExpanseDown = () => <>
  <div><ArrowDownOne color="currentColor" /></div>
</>;

const IconStaff = () => <>
  <div><SvgStaff color="currentColor" /></div>
</>;

const IconGrant = () => <>
  <div><SvgGrant color="currentColor" /></div>
</>;

const IconWechat = () => <>
  <div><SvgWechat color="currentColor" /></div>
</>;

const IconReportAd = () => <>
  <div><SvgReportAd color="currentColor" /></div>
</>;

const IconMember = () => <>
  <div><SvgMember color="currentColor" /></div>
</>;

const IconCustomMarketing = () => <>
  <div><SvgCustomMarketing color="currentColor" /></div>
</>;

const IconGroupMsgTool = () => <>
  <div><SvgGroupMsgTool color="currentColor" /></div>
</>;

const IconMarketingPlan = () => <>
  <div><SvgMarketingPlan color="currentColor" /></div>
</>;

const IconChatTool = () => <>
  <div><SvgChatTool color="currentColor" /></div>
</>;

const IconCustomManage = () => <>
  <div><SvgCustomManage color="currentColor" /></div>
</>;

const IconCustomGrouManage = () => <>
  <div><SvgCustomGrouManage color="currentColor" /></div>
</>;

const IconAccountManage = () => <>
  <div><SvgAccountManage color="currentColor" /></div>
</>;

const IconSetting = (props: any) => <>
  <div><SvgSetting color={props.selected ? '#296AEF' : 'currentColor'} /></div>
  <span><SvgSetting color="#296AEF" /></span>
</>;

const IconConversation = () => <>
  <div><SvgConversation color="currentColor" /></div>
</>;

const IconHelp = () => <>
  <div><SvgHelp color="currentColor" /></div>
  <span><SvgHelp color="#296AEF" /></span>
</>;

const IconWorkBench = () => <>
  <div><SvgWorkBench color="currentColor" /></div>
</>;

const IconAdService = () => <>
  <b><SvgAdService color="currentColor" /></b>
  <span><SvgAdServiceOver color="#296BEF" /></span>
</>;

const IconRiseEngine = () => <>
  <b><SvgRiseEngine color="currentColor" /></b>
  <span><SvgRiseEngineOver color="#296BEF" /></span>
</>;

const IconMarketingDiscover = () => <>
  <div><SvgMarketingDiscover color="currentColor" /></div>
</>;

const IconMarketingSandtable = () => <>
  <div><SvgMarketingSandtable color="currentColor" /></div>
</>;

const IconShopingHelp = () => <>
  <b><SvgShopingHelp color="currentColor" /></b>
  <span><SvgShopingHelpOver color="#296BEF" /></span>
</>;

const IconLinkAnalysis = () => <>
  <b><SvgAllRoad color="currentColor" /></b>
  <span><SvgAllRoadOver color="#296BEF" /></span>
</>;

const IconAILab = () => <>
  <b><SvgAILab color="currentColor" /></b>
  <span><SvgAILabOver color="#296BEF" /></span>
</>;

const IconOpsTool = () => <>
  <b><SvgOpsTool color="currentColor" /></b>
  <span><SvgOpsToolOver color="#296BEF" /></span>
</>;

const IconLinkFunnel = () => <>
  <div><SvgLinkFunnel color="currentColor" /></div>
</>;

const IconSvgAiShopping = () => <>
  <div><SvgAiShopping color="currentColor" /></div>
</>;

const IconSvgShoppingSop = () => <>
  <div><SvgShoppingSop color="currentColor" /></div>
</>;

export {
  IconGrant,
  IconWechat,
  IconReportDashbord,
  IconReportAd,
  IconMember,
  IconStaff,
  IconExpanseUp,
  IconExpanseDown,
  IconCustomMarketing,
  IconGroupMsgTool,
  IconMarketingPlan,
  IconChatTool,
  IconCustomManage,
  IconCustomGrouManage,
  IconAccountManage,
  IconSetting,
  IconConversation,
  IconHelp,
  IconWorkBench,
  IconAdService,
  IconRiseEngine,
  IconMarketingDiscover,
  IconMarketingSandtable,
  IconShopingHelp,
  IconLinkAnalysis,
  IconAILab,
  IconOpsTool,
  IconLinkFunnel,
  IconSvgAiShopping,
  IconSvgShoppingSop,
};
