const HOST = ZhbHost;

const authApi = {
  // 验证码发送
  sendPhoneAuth: `${HOST}/api/auth/registered/SendPhoneAuth`,

  // 角色列表
  getRoleList: `${HOST}/api/auth/getRoleList`,

  // 创建角色
  createRole: `${HOST}/api/auth/createRole`,

  // 更新/删除角色
  updateRole: `${HOST}/api/auth/updateRole`,

  // 获取子账号列表
  getSubAccountList: `${HOST}/api/auth/getSubAccountList`,

  // 创建账号:
  createSubAccount: `${HOST}/api/auth/createSubAccount`,

  // 更新账号
  updateSubAccount: `${HOST}/api/auth/updateSubAccount`,

  // 修改密码
  changePassword: `${HOST}/api/auth/changePassword`,

  // 广告账户-授权列表
  getBindAccountList: `${HOST}/api/auth/getBindAccountList`,

  // 广告账户-新增授权
  createBindAccount: `${HOST}/api/auth/createBindAccount`,

  // 广告账户-同意/拒绝/删除授权
  updateBindAccount: `${HOST}/api/auth/updateBindAccount`,

  // 登录
  login: `${HOST}/api/auth/otherLogin`,

  // sso登录
  ssoLogin: `${HOST}/api/auth/ssoLogin`,

  // 注册
  register: `${HOST}/api/auth/registered/openShop`,

  // 验证码验证
  checkCode: `${HOST}/api/auth/registered/checkPhoneAuth`,

  // 手机号检测
  checkPhone: `${HOST}/api/auth/registered/checkPhone`,

  // 获取登陆用户信息
  getUserInfo: `${HOST}/api/auth/getOtherUserInfo`,

  // 获取名称
  modifyName: `${HOST}/api/auth/user/modifyName`,
};

export { authApi };
