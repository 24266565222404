import React, { useState } from 'react';
import { Input } from '@tencent/adp-combine-ui';
import { Button as TeaButton, Select } from '@tencent/tea-component';
import searchIcon from 'maker/assets/accountManage/search_btn.png';
import styles from './search.less?local';

interface SearchProps {
  onValueChange?: (v: string) => void;
  onSearch?: any;
  placeholder?: string;
  needFilter?: boolean;
  extendFilterChild?: React.ReactFragment;
  searchRef?: any;
  width?: number | string;
  defaultValue: string;
}

const SearchCompotent = (props: SearchProps) => {
  const [value, setValue] = useState<string>(props.defaultValue || '');
  // const [extendValue, setExtendValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('-99');

  const options = [
    {
      value: 'normal',
      text: '正常使用',
    },
    { value: 'disabled', text: '已停用', tooltip: '' },
  ];

  // if (props.searchRef) {
  //   props.searchRef.current = {
  //     setSearchExtendValue: setExtendValue,
  //   }
  // }

  return (
    <div className={styles['input-wrapper']}>
      <div className={styles.icon}></div>
      <Input
        value={value}
        onValueChange={(val) => {
          setValue(val);
          if (props.onValueChange) {
            props.onValueChange(val);
          }
        }}
        clearable
        placeholder={props.placeholder ? props.placeholder : '搜索关键词'}
        max={4}
        maxLength={60}
        style={{ width: props.width ? props.width : 200 }}
        onPressEnter={() => {
          props.onSearch(value, '');
        }}
        onClear={() => {
          props.onSearch('', '');
        }}
        className={styles.input}
      />
      {props.extendFilterChild}
      {props.needFilter && <>
        <Select appearance='pure'
          options={options} value={filterValue}
          onChange={
            (v) => {
              setFilterValue(v);
              props.onSearch(value, v);
            }
          }
          button={<><img style={{ marginLeft: 16 }} src={searchIcon} width={14} height={14} />
            <TeaButton type='text'>筛选</TeaButton></>}
        />
      </>
      }
    </div >
  );
};

export default SearchCompotent;
