import React, { useState, useMemo, useRef } from 'react';
import WujieReact from 'wujie-react';
import styles from './index.less?local';
import { Popover, message, Tooltip } from '@tencent/adp-combine-ui';
import { ArrowDown, ArrowUp } from '@tencent/adp-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useGlobalContext } from 'maker/hooks/useGlobalStore';
import { clearSessions } from 'maker/utils/session';
import ModifyPasswordDialog from 'maker/routes/AccountManage/components/modifyPassword';
import Avatar from 'maker/assets/layouts/avatar.png';
import { SvgInfoCopy } from 'maker/components/Icon/icon';
import ModStoreName from './modStoreName';
import Ellipsis from 'maker/components/Ellipsis/tooltips';

const { destroyApp } = WujieReact;

interface UserInfoProps {
  showName?: boolean;
}


const UserInfo = (props: UserInfoProps) => {
  const { state, dispatch } = useGlobalContext();
  const { roleName, nickName, accountName, phone, zid, fid, history, isAdmin, userType } = state;
  const [isShowPover, setShowPover] = useState<boolean>(false);
  const [showModName, setShowModName] = useState<boolean>(false);
  const modifyPasswordRef = useRef<any>(null);

  // 退出
  const logout = () => {
    dispatch({
      type: 'setData',
      data: { nickName: '' },
    });
    clearSessions();
    history.push('/login');
    destroyApp('climb-web-main');
    destroyApp('climb-web-sub');
  };

  // 复制corpId
  const onCopy = () => {
    message.success('转化宝ID已复制至剪切板');
  };

  const userName =  fid || phone;
  const storeName = `${nickName}${accountName ? `_${accountName}` : ''}`;

  const renderDropList = useMemo(() => <>
    <div className={styles['popver-wrapper-selected-subtitle']}>当前登录账号</div>
    <div className={styles['popver-wrapper-selected-title']}>{userName}</div>
    {!fid && <div className={styles['popver-wrapper-selected-info']}>
      <label>商家名称：</label>
      <div className={styles['popver-wrapper-selected-info-text']}>
        {isShowPover && <Ellipsis>{storeName}</Ellipsis>}
      </div>
    </div>}
    {roleName && <div className={styles['popver-wrapper-selected-info']}>
      <label>账号角色：</label>
      <div className={styles['popver-wrapper-selected-info-text']}>{roleName}</div>
    </div>}
    <div className={styles['popver-wrapper-selected-info']}>
      <label>转化宝ID：</label>
      <div className={styles['popver-wrapper-selected-info-text']}>{zid}</div>
      <CopyToClipboard text={zid} onCopy={onCopy}>
        <div className={styles['popver-wrapper-selected-info-copy']}>
          <Tooltip
            theme="dark"
            trigger="hover"
            placement="top"
            getPopper="复制ID"
            lazyInit
            arrow
          >
            <SvgInfoCopy />
          </Tooltip>
        </div>
      </CopyToClipboard>

    </div>
    <div className={styles['popver-wrapper-select-list']}>
      {isAdmin && userType === 1 && <div className={styles['popver-wrapper-select-list-option']}
        onClick={() => {
          setShowModName(true);
          setShowPover(false);
        }}
      >修改商家名称</div>}
      {!fid && <div className={styles['popver-wrapper-select-list-option']}
        onClick={() => {
          setShowPover(false);
          modifyPasswordRef.current?.onAddShow();
        }}
      >修改账号密码</div>}
      <div onClick={logout} className={styles['popver-wrapper-select-list-option']}>退出</div>
    </div>
  </>, [roleName, nickName, userName, isShowPover]);

  return useMemo(() => (
    <div className={styles['select-wrapper']}>
      <Popover
        trigger="click"
        show={isShowPover}
        placement="bottom-end"
        popoverStyle={{ padding: 0, marginTop: -5 }}
        getPopper={() => <div className={styles['popver-wrapper']}>
          {renderDropList}
        </div>}
        onVisibleChange={(v: boolean) => {
          setShowPover(v);
        }}
      >
        <div className={`${styles['selected-item']} ${isShowPover ? styles.selected : ''}`}>
          <span className={styles['selected-item-icon']}><img src={Avatar} alt="avatar" width={20} /></span>
          {props.showName && <span className={styles['selected-item-name']}>{userName}</span>}
          <span className={styles['selected-item-drapdown']}>
            {isShowPover
              ? <ArrowUp fill="#4D5869" size={10} />
              : <ArrowDown fill="#4D5869" size={10} />}
          </span>
        </div>
      </Popover>
      <ModifyPasswordDialog dialogRef={modifyPasswordRef} />
      <ModStoreName show={showModName} onHide={() => {
        setShowModName(false);
      }} />
    </div>
  ), [isShowPover, userName, props.showName, showModName, setShowModName]);
};

export default UserInfo;
